import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  SvgIcon,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CreateItem, fetchSettingsData } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProduct({ mode, data }) {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    item_name: data?.product.item_name || "",
    initial_stock: data?.total_in_stock || "0",
    unit_of_measurement: data?.product.unit_of_measurement || "",
    notes: data?.product.notes || "",
    is_consumable: data?.product.is_consumable || false,
    item_number: data?.product.item_number || "N/A",
    category: data?.product.category || "",
  });

  const [settingsType, setSettingsType] = React.useState([]);

  useEffect(() => {
    fetchSettingsData(setSettingsType);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    // For is_consumable field, convert the value to a boolean
    const sanitizedValue =
      field === "is_consumable" ? value === "true" || value === true : value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: sanitizedValue,
    }));
  };

  const handleSubmit = async () => {
    // Validation checks
    if (
      !formValues.item_name ||
      !formValues.item_number ||
      !formValues.unit_of_measurement ||
      !formValues.category
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Data to be posted
    const postData = {
      id: data?.product?.id,
      item_name: formValues.item_name,
      initial_stock: formValues.initial_stock,
      unit_of_measurement: formValues.unit_of_measurement,
      notes: formValues.notes,
      is_consumable: formValues.is_consumable,
      is_tangible: "true",
      item_number: formValues.item_number,
      category: formValues.category,
    };

    await CreateItem(postData, endpoints.NEW_INVENTORY_ENDPOINT);
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setFormValues({
        item_name: data?.product.item_name || "",
        initial_stock: data?.total_in_stock || "0",
        unit_of_measurement: data?.product.unit_of_measurement || "",
        notes: data?.product.notes || "",
        is_consumable: data?.product.is_consumable || false,
        item_number: data?.product.item_number || "",
        category: data?.product.category || "",
      });
      handleClickOpen();
    }
  }, [mode, data]);

  return (
    <React.Fragment>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PlusIcon />
          </SvgIcon>
        }
        variant="contained"
        onClick={handleClickOpen}
      >
        Add a product
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    mode === "edit" ? "edit" : "create"
                  } an item`}
                  title={`${mode === "edit" ? "EDIT" : "CREATE"} an item`}
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Item Name",
                      placeholder: "",
                      field: "item_name",
                    },
                    {
                      labelName: "Barcode Number",
                      placeholder: "",
                      field: "item_number",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.labelName}
                          <span style={{ color: "red" }}> *</span>
                        </p>

                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            value={formValues[inputField.field]}
                            defaultValue=""
                            fullWidth
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      </ListItem>
                    </React.Fragment>
                  ))}
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Category
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={
                          settingsType?.item_categories
                            ? settingsType.item_categories.split(",")
                            : []
                        }
                        value={formValues.category}
                        onChange={(event, value) =>
                          handleInputChange("category", value)
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>

                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Unit Of Measurement
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={
                          settingsType?.units_of_measurements
                            ? settingsType.units_of_measurements.split(",")
                            : []
                        }
                        value={formValues.unit_of_measurement}
                        onChange={(event, value) =>
                          handleInputChange("unit_of_measurement", value)
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    {[
                      {
                        labelName: "Is Consumable",
                        placeholder: "",
                        field: "is_consumable",
                        type: "radio",
                        options: [
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ],
                      },

                      {
                        labelName: "Initial Stock Qty",
                        placeholder: "",
                        field: "initial_stock",
                      },
                      // {
                      //   labelName: "Narration (optional) ",
                      //   placeholder: "",
                      //   field: "notes",
                      // },
                    ].map((inputField, index) => (
                      <React.Fragment key={index}>
                        <ListItem
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "start",
                          }}
                        >
                          {inputField.labelName === "Narration (optional) " ? (
                            <p>
                              {inputField.labelName}
                              <span style={{ color: "red" }}></span>
                            </p>
                          ) : (
                            <p>
                              {inputField.labelName}
                              <span style={{ color: "red" }}> *</span>
                            </p>
                          )}

                          {inputField.type === "radio" ? (
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                value={formValues[inputField.field]}
                                onChange={(e) =>
                                  handleInputChange(
                                    inputField.field,
                                    e.target.value
                                  )
                                }
                              >
                                {inputField.options.map(
                                  (option, optionIndex) => (
                                    <FormControlLabel
                                      sx={{ marginRight: "10px" }}
                                      key={optionIndex}
                                      value={option.value}
                                      control={<Radio />}
                                      label={option.label}
                                    />
                                  )
                                )}
                              </RadioGroup>
                            </FormControl>
                          ) : inputField.labelName === "Initial Stock Qty" ? (
                            <TextField
                              multiline
                              minRows={1}
                              maxRows={1}
                              variant="outlined"
                              value={formValues[inputField.field]}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          ) : inputField.labelName ===
                            "Narration (optional) " ? (
                            <TextField
                              multiline
                              value={formValues[inputField.field]}
                              minRows={4}
                              maxRows={6}
                              variant="outlined"
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                defaultValue=""
                                fullWidth
                                onChange={(e) =>
                                  handleInputChange(
                                    inputField.field,
                                    e.target.value
                                  )
                                }
                              />
                            </FormControl>
                          )}
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </ListItem>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
