import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  SvgIcon,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CreateItem, makeNetworkCall } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { DEFAULT_PER_PAGE } from "../../utils/constants";
import { toast } from "react-toastify";
import { clearFormatNumber, formatNumber } from "../../utils/number-formter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const useGroupedPurchaseOrders = (
  page,
  rowsPerPage,
  order_number,
  order_id
) => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    order_number: order_number,
    order_id: order_id,
  };
  useEffect(() => {
    const fetch = async () =>
      makeNetworkCall(
        endpoints.SCHOOL_GROUPED_ORDERS_ENDPOINT,
        params,
        setData,
        setTotals,
        setIsLoading,
        {}
      );
    fetch();
  }, [page, rowsPerPage]);

  return { data, totals, isLoading, setTotals, setData, setIsLoading };
};
export default function AddDelivery({ purchaseOrderData, type, data, mode }) {
  const order = purchaseOrderData?.order || data?.delivery.purchase_order;
  const { data: purchaseOrdersData } = useGroupedPurchaseOrders(
    0,
    DEFAULT_PER_PAGE,
    order.order_number,
    order.id
  );
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    notes: "",
    delivered_by: "",
    product_id: [""],
    purchase_order_id: [""],
    quantities: type === "lso" ? ["1"] : [""],
    invoiceAmounts: [""],
  });
  const [unitOfMeasurements, setUnitOfMeasurements] = useState("");
  const [isDelivered, setIsDelivered] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (index, field, value) => {
    setFormValues((prevValues) => {
      const updatedValues = [...prevValues[field]];
      updatedValues[index] = value;
      return {
        ...prevValues,
        [field]: updatedValues,
      };
    });
  };
  const handleDeliveredByChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      delivered_by: e.target.value,
    }));
  };
  const handleOrderInputChange = (index, field, value) => {
    const updatedFormValues = [...formValues[field]];
    updatedFormValues[index] = value;
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: updatedFormValues,
    }));
  };

  const handleAddField = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      quantities: [...prevValues.quantities, ""],
      invoiceAmounts: [...prevValues.invoiceAmounts, ""],
      purchase_order_id: [...prevValues.purchase_order_id, ""],
    }));
    setUnitOfMeasurements((prevMeasurements) => [...prevMeasurements, ""]);
  };

  const handleRemoveField = (index) => {
    setFormValues((prevValues) => {
      const updatedQuantities = [...prevValues.quantities];
      updatedQuantities.splice(index, 1);
      const updatedInvoiceAmounts = [...prevValues.invoiceAmounts];
      updatedInvoiceAmounts.splice(index, 1);
      const updatedPurchaseOrderIds = [...prevValues.purchase_order_id];
      updatedPurchaseOrderIds.splice(index, 1);
      return {
        ...prevValues,
        quantities: updatedQuantities,
        invoiceAmounts: updatedInvoiceAmounts,
        purchase_order_id: updatedPurchaseOrderIds,
      };
    });
    setUnitOfMeasurements((prevMeasurements) => {
      const updatedMeasurements = [...prevMeasurements];
      updatedMeasurements.splice(index, 1);
      return updatedMeasurements;
    });
  };

  const handleProductAllocationSubmit = async () => {
    if (
      !formValues.quantities.every((q) => q) ||
      !formValues.invoiceAmounts.every((ia) => ia) ||
      !formValues.delivered_by ||
      (mode !== "edit" &&
        data === null &&
        !formValues.purchase_order_id.every((id) => id))
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    let purchaseOrderId = 0;
    if (mode === "edit" && data) {
      purchaseOrderId = data?.delivery.purchase_order?.id;
    } else {
      purchaseOrderId = formValues.purchase_order_id.join(",");
    }
    const postData = {
      id: data?.delivery.id || "",
      notes: "",
      quantity: formValues.quantities.map(clearFormatNumber).join(","),
      delivered_by: formValues.delivered_by,
      purchase_order_id: formValues.purchase_order_id.join(","),
      invoice_amount: formValues.invoiceAmounts
        .map(clearFormatNumber)
        .join(","),
      product_id: formValues.product_id.map(clearFormatNumber).join(","),
    };

    // Call API to create or update delivery item
    await CreateItem(postData, endpoints.NEW_DELIVERY_ENDPOINT);

    // Close the dialog after successful submission
    handleClose();
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setFormValues({
        delivered_by: data?.delivery.delivered_by || "",
        product_id: data?.delivery.purchase_order?.product?.id || "",
        purchase_order_id: data?.delivery.purchase_order?.id || [""],
        quantities: type === "lso" ? ["1"] : [data?.delivery.quantity] || [""],
        invoiceAmounts: [data?.delivery.invoice_amount] || [""],
        notes: data?.delivery.notes || "",
      });

      handleClickOpen();
    }
  }, [mode, data, type, purchaseOrderData]);

  useEffect(() => {
    if (purchaseOrderData) {
      setFormValues((prevValues) => ({
        ...prevValues,
        product_id: [order?.product?.id],
      }));
    }
  }, [purchaseOrderData]);

  useEffect(() => {
    if (purchaseOrdersData && mode !== "edit") {
      // Filter out purchase orders where remaining_qty is less than one
      const validPurchaseOrders = purchaseOrdersData.filter(
        (data) => data.remaining_qty >= 1
      );

      // Set the isDelivered state to true if no valid purchase orders are found
      if (validPurchaseOrders.length === 0) {
        setIsDelivered(true);
      } else {
        setIsDelivered(false); // Ensure isDelivered is false if there are valid purchase orders
      }
      setFormValues({
        product_id: validPurchaseOrders.map(
          (data) => data.order?.product?.id
        ) || [""],
        purchase_order_id: validPurchaseOrders.map((data) => data.order.id) || [
          "",
        ],
        quantities:
          type === "lso"
            ? ["1"]
            : validPurchaseOrders.map((data) => data.remaining_qty) || [""],
        invoiceAmounts: validPurchaseOrders.map(
          (data) => data.order.order_amount
        ) || [""],
      });

      setUnitOfMeasurements(
        validPurchaseOrders.map(
          (data) => data.order.product.unit_of_measurement
        ) || [""]
      );
    }
  }, [purchaseOrderData, purchaseOrdersData, type]);

  return (
    <React.Fragment>
      {mode === "edit" ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={handleClickOpen}
        >
          {type === "lso" ? "Complete Service" : "Receive Delivery"}
        </Button>
      )}

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <List>
          <Container sx={{ marginTop: "1rem" }}>
            {isDelivered && mode !== "edit" ? (
              <CardHeader
                title={`${
                  type === "lso"
                    ? "ALL THE LSO DELIVERIES FROM"
                    : "ALL THE LPO DELIVERIES FROM"
                }  ${order?.supplier?.company_name}, ${
                  type === "lso" ? "LSO NO." : "LPO NO."
                } ${order?.order_number} HAS BEEN RECEIVED`}
              />
            ) : (
              <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ width: "100%" }}>
                  <CardHeader
                    subheader={`Fill in all the inputs to be able to ${
                      type === "lso"
                        ? mode !== "edit"
                          ? "Complete Service"
                          : "Edit Service"
                        : mode !== "edit"
                        ? "Receive a Delivery"
                        : "Edit Delivery"
                    }`}
                    title={`${
                      type === "lso"
                        ? mode !== "edit"
                          ? "COMPLETE DELIVERY FROM"
                          : "EDIT DELIVERY FROM"
                        : mode !== "edit"
                        ? "RECEIVE DELIVERY FROM"
                        : "EDIT DELIVERY FROM"
                    }  ${order?.supplier?.company_name}, ${
                      type === "lso" ? "LSO NO." : "LPO NO."
                    } ${order?.order_number}`}
                  />

                  <Divider />
                </Box>
                <Grid sx={{ padding: 3 }} container xs={12} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <p>
                      Delivered By (Name - Phone)
                      <span style={{ color: "red" }}>* </span>
                    </p>
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        defaultValue=""
                        fullWidth
                        value={formValues.delivered_by}
                        onChange={handleDeliveredByChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {formValues.quantities.map((quantity, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          p: 2,
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={12} md={4}>
                          {/* Conditionally render Autocomplete for Purchase Order on added inputs */}
                          {/* {index > 0 && ( */}
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                              marginTop: "10px",
                            }}
                          >
                            <FormControl fullWidth>
                              <p>
                                Inventory
                                <span
                                  style={{ color: "red", marginLeft: "5px" }}
                                >
                                  *{" "}
                                </span>
                              </p>
                              <Autocomplete
                                disabled
                                disablePortal
                                options={
                                  purchaseOrdersData ? purchaseOrdersData : []
                                }
                                getOptionLabel={(order) =>
                                  `${order?.order?.product?.item_name}`
                                }
                                value={
                                  purchaseOrdersData?.find(
                                    (order) =>
                                      order?.order?.id ===
                                      formValues.purchase_order_id[index]
                                  ) ||
                                  purchaseOrdersData?.find(
                                    (order) =>
                                      order?.order?.id ===
                                      formValues.purchase_order_id[index]
                                  )
                                }
                                onChange={(e, value) =>
                                  handleOrderInputChange(
                                    index,
                                    "purchase_order_id",
                                    value?.id
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </FormControl>
                          </ListItem>
                          {/* // )} */}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              Quantity {unitOfMeasurements[index]}
                              <span style={{ color: "red" }}>* </span>
                            </p>
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                value={formatNumber(quantity)}
                                fullWidth
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "quantities",
                                    e.target.value.replace(/,/g, "")
                                  )
                                }
                              />
                            </FormControl>
                          </ListItem>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              Invoice Amount
                              <span style={{ color: "red" }}>* </span>
                            </p>
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                value={formatNumber(
                                  formValues.invoiceAmounts[index]
                                )}
                                fullWidth
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "invoiceAmounts",
                                    e.target.value.replace(/,/g, "")
                                  )
                                }
                              />
                            </FormControl>
                          </ListItem>
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <ListItem>
                            {formValues.quantities.length > 1 && (
                              <Button
                                color="secondary"
                                onClick={() => handleRemoveField(index)}
                                sx={{ marginTop: "10px" }}
                              >
                                Remove
                              </Button>
                            )}
                            {/* {mode !== "edit" && (
                            <Button
                              variant="outlined"
                              onClick={handleAddField}
                              sx={{ marginTop: "10px" }}
                            >
                              Receive another Delivery
                            </Button>
                          )} */}
                          </ListItem>
                        </Grid>
                      </Box>
                    ))}
                  </Grid>

                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                      marginTop: "1rem",
                    }}
                  >
                    <Button
                      onClick={handleProductAllocationSubmit}
                      sx={{ width: "100%", marginRight: "5px" }}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </ListItem>
                </Grid>
              </Card>
            )}
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
