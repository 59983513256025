import {useMemo} from "react";
import {useSelection} from "../../hooks/use-selection";
import {PaymentSearch} from "../../sections/payments/payments-search";
import {PaymentTable} from "../../sections/payments/payments-table";
import * as actions from "../../layouts/dashboard/actions";
import {SCHOOL_PAYMENTS_ENDPOINT} from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import {useLoadData} from "../../services/featching-data-service";


const usePaymentIds = (payments) => {
    return useMemo(() => {
        return payments.map((payment) => payment.id);
    }, [payments]);
};

const PaymentsPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();
    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
    };
    const {
        data: payments,
        totals,
        setTotals,
        isLoading,
        setIsLoading,
        setData,
    } = useLoadData(payload, SCHOOL_PAYMENTS_ENDPOINT);
    const paymentIds = usePaymentIds(payments);
    const paymentsSelection = useSelection(paymentIds);

    return BasePage(
        "Supplier Payments",
        null,
        <PaymentSearch
            data={payments}
            setTotals={setTotals}
            payload={payload}
            setData={setData}
            setIsLoading={setIsLoading}
            baseEndpoint={SCHOOL_PAYMENTS_ENDPOINT}
        />,
        <PaymentTable
            totals={totals}
            items={payments}
            onDeselectAll={paymentsSelection.handleDeselectAll}
            onDeselectOne={paymentsSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={paymentsSelection.handleSelectAll}
            onSelectOne={paymentsSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={paymentsSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef,
        actions.canAddPayment
    );
};

export default PaymentsPage;
