import {Box, Container, Stack, Tab, Tabs, Typography,} from "@mui/material";
import AddStudentAllocations from "../../../sections/studentAllocations/add-student_allocations";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useSelection} from "../../../hooks/use-selection";
import {applyPagination} from "../../../utils/apply-pagination";
import {useParams} from "react-router-dom";
import {StudentIssuedItemsTable} from "../../../sections/studentAllocations/student/issued-student-items-table";
import {StudentReceivedItemsTable} from "../../../sections/studentAllocations/student/received-student-items-table";
import {DEFAULT_PER_PAGE, SEARCH_STUDENT_ENDPOINT, STUDENT_ITEMS_ENDPOINT} from "../../../utils/constants";
import {makeNetworkCall} from "../../../utils/fetchData";

const Student = () => {
    const [tapValue, setTapValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
    const [studentData, setStudentData] = useState([]);
    const {id: student_number, student_name, form, stream} = useParams();

    const handleTapChange = (event, newValue) => {
        setTapValue(newValue);
    };

    useEffect(() => {
        const getStudent = async () => makeNetworkCall(
            SEARCH_STUDENT_ENDPOINT,
            {
                student_number: student_number,
            },
            setStudentData,
            null,
            null,
            {},
            "POST"
        );
        getStudent();
    }, []);

    useEffect(() => {
        const status = tapValue === 0 ? "issued" : "received";
        const fetchData = async () => makeNetworkCall(
            STUDENT_ITEMS_ENDPOINT,
            {
                student_number: student_number,
                status: status,
            },
            setData,
            null,
            setIsLoading,
            {},
            "POST"
        );

        fetchData();
    }, [tapValue, student_number]);

    const allocationIds = useMemo(() => {
        return data.map((allocation) => allocation.id);
    }, [data]);

    const allocationsSelection = useSelection(allocationIds);

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);
    return (
        <>
            <Box component="main" sx={{flexGrow: 1, py: 8}}>
                <Container maxWidth="false">
                    <Stack spacing={3}>
                        <Stack direction="row" justifyContent="space-between" spacing={4}>
                            <Stack spacing={1}>
                                <Typography variant="h5">
                                    {studentData[0]?.student_name}, ADM{" "}
                                    {studentData[0]?.student_number}, F{studentData[0]?.form}{" "}
                                    {studentData[0]?.stream} Details
                                </Typography>
                                <Stack alignItems="center" direction="row" spacing={1}></Stack>
                            </Stack>
                            <AddStudentAllocations
                                student_number={student_number}
                                rowsPerPage={rowsPerPage}
                                page={page}
                            />
                        </Stack>

                        <Tabs
                            value={tapValue}
                            onChange={handleTapChange}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            <Tab label="Items Issued to Student"/>
                            <Tab label="Items Received from Student"/>
                        </Tabs>
                        <Box>
                            {tapValue === 0 && (
                                <StudentReceivedItemsTable
                                    items={data}
                                    onDeselectAll={allocationsSelection.handleDeselectAll}
                                    onDeselectOne={allocationsSelection.handleDeselectOne}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    onSelectAll={allocationsSelection.handleSelectAll}
                                    onSelectOne={allocationsSelection.handleSelectOne}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    selected={allocationsSelection.selected}
                                    isLoading={isLoading}
                                />
                            )}
                            {tapValue === 1 && (
                                <StudentIssuedItemsTable
                                    items={applyPagination(data, page, rowsPerPage)}
                                    onDeselectAll={allocationsSelection.handleDeselectAll}
                                    onDeselectOne={allocationsSelection.handleDeselectOne}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    onSelectAll={allocationsSelection.handleSelectAll}
                                    onSelectOne={allocationsSelection.handleSelectOne}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    selected={allocationsSelection.selected}
                                    isLoading={isLoading}
                                />
                            )}
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default Student;
