import { useMemo } from "react";
import { useSelection } from "../../hooks/use-selection";
import { PurchaseOrdersSearch } from "../../sections/purchaseOrders/purchaseOrders-search";
import { PurchaseOrdersTable } from "../../sections/purchaseOrders/purchaseOrders-table";
import { SCHOOL_ORDERS_ENDPOINT } from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import { useLoadData } from "../../services/featching-data-service";

// export const usePurchaseOrders = (
//   page,
//   rowsPerPage,
//   active_orders = "false"
// ) => {
//   const [data, setData] = useState([]);
//   const [totals, setTotals] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const params = {
//     offset: page * rowsPerPage,
//     per_page: rowsPerPage,
//     active_orders: active_orders,
//   };
//   useEffect(() => {
//     const fetch = async () =>
//       makeNetworkCall(
//         SCHOOL_ORDERS_ENDPOINT,
//         params,
//         setData,
//         setTotals,
//         setIsLoading,
//         {}
//       );
//     fetch();
//   }, [page, rowsPerPage, active_orders]);

//   return { data, totals, isLoading, setTotals, setData, setIsLoading };
// };

const usePurchaseOrderIds = (purchaseOrders) => {
  return useMemo(() => {
    return purchaseOrders.map((order) => order.id);
  }, [purchaseOrders]);
};

const OrderPage = () => {
  const {
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    componentRef,
  } = BasePageConfigs();

  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
  };

  const {
    data: purchaseOrders,
    totals,
    isLoading,
    setTotals,
    setData,
    setIsLoading,
  } = useLoadData(payload, SCHOOL_ORDERS_ENDPOINT);
  const purchaseOrderIds = usePurchaseOrderIds(purchaseOrders);
  const purchaseOrdersSelection = useSelection(purchaseOrderIds);

  return BasePage(
    "Purchase Orders",
    null,
    <PurchaseOrdersSearch
      setIsLoading={setIsLoading}
      setData={setData}
      setTotals={setTotals}
      payload={payload}
      baseEndpoint={SCHOOL_ORDERS_ENDPOINT}
    />,
    <PurchaseOrdersTable
      totals={totals}
      items={purchaseOrders}
      onDeselectAll={purchaseOrdersSelection.handleDeselectAll}
      onDeselectOne={purchaseOrdersSelection.handleDeselectOne}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onSelectAll={purchaseOrdersSelection.handleSelectAll}
      onSelectOne={purchaseOrdersSelection.handleSelectOne}
      page={page}
      rowsPerPage={rowsPerPage}
      selected={purchaseOrdersSelection.selected}
      isLoading={isLoading}
      componentRef={componentRef}
    />,
    componentRef
  );
};

export default OrderPage;
