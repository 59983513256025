import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { makeNetworkCall } from "../../utils/fetchData";
import { MINIFIED_PRODUCTS_ENDPOINT } from "../../utils/constants";

const ProductsSelection = ({
  index,
  setOrderValues,
  setProduct,
  productType = "Services",
  initialProduct,
}) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(initialProduct);

  useEffect(() => {
    makeNetworkCall(
      MINIFIED_PRODUCTS_ENDPOINT,
      {
        product_type: productType,
      },
      setProducts,
      null,
      null,
      {},
      "POST"
    );
  }, [productType]);

  const handleProductSelection = (value) => {
    const productId = value ? value.id : "";
    const unitOfMeasurement = value ? value.unit_of_measurement : "";
    setProduct(index, ` in ${unitOfMeasurement}`);
    setOrderValues(index, "product_id", productId);
    setSelectedProduct(value); // Update selected product state
  };

  return (
    <FormControl fullWidth>
      <p>
        {productType}
        <span style={{ color: "red", marginLeft: "5px" }}>* </span>
      </p>
      <Autocomplete
        options={products}
        getOptionLabel={(option) =>
          option.item_number
            ? `${option.item_name} (#${option.item_number})`
            : option.item_name
        }
        value={selectedProduct} // Set selected product value
        onChange={(event, value) => handleProductSelection(value)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" fullWidth />
        )}
      />
    </FormControl>
  );
};

export default ProductsSelection;
