import {
  Box,
  Button,
  ListItemText,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ORDER_DELIVERIES_ENDPOINT,
  ORDER_PAYMENTS_ENDPOINT,
  SINGLE_ORDER_ENDPOINT,
} from "../../../utils/constants";
import { makeNetworkCall } from "../../../utils/fetchData";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { ExportTableButton } from "../../../utils/export-document";
import { printReport } from "../../../utils/print-documents";
import { PaymentTable } from "../../../sections/payments/payments-table";
import { DeliveriesTable } from "../../../sections/deliveries/deliveries-table";
import AddDelivery from "../../../sections/deliveries/add-deliveries";
import AddPayment from "../../../sections/payments/add-payments";
import ProgressBars from "../../../utils/loading";

const useServices = (page, rowsPerPage, id, product_id) => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentsTotals, setPaymentTotals] = useState({});
  const [deliveryTotals, setDeliveriesTotals] = useState({});

  useEffect(() => {
    makeNetworkCall(
      SINGLE_ORDER_ENDPOINT,
      {
        order_id: id,
      },
      setSingleOrder,
      null,
      setIsLoading,
      {}
    );

    makeNetworkCall(
      ORDER_PAYMENTS_ENDPOINT,
      {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
        order_id: id,
      },
      setPaymentsData,
      setPaymentTotals,
      setIsLoading,
      {}
    );
    makeNetworkCall(
      ORDER_DELIVERIES_ENDPOINT,
      {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
        order_id: id,
      },
      setDeliveriesData,
      setDeliveriesTotals,
      setIsLoading,
      {}
    );
  }, [page, rowsPerPage, id]);

  return {
    singleOrder,
    paymentsData,
    paymentsTotals,
    deliveryTotals,
    isLoading,
    deliveriesData,
  };
};

const LsoOrder = () => {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [value, setValue] = useState(0);
  const {
    singleOrder,
    paymentsData: servicesPayments,
    paymentsTotals,
    deliveryTotals,
    isLoading,
    deliveriesData: servicesDeliveries,
  } = useServices(page, rowsPerPage, id);
  const order = singleOrder[0];
  const componentRef = useRef();
  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading || order === undefined ? (
        <ProgressBars />
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2,
          }}
        >
          <Stack spacing={3} sx>
            <Stack direction="row" justifyContent="space-between" spacing={0}>
              <Stack direction="row" alignItems="center" spacing={0}>
                <div style={{ marginLeft: "32px" }}>
                  <ListItemText
                    primary={order?.order?.supplier?.company_name}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                    secondary={`${order?.order?.order_number}, KSH. ${order?.order?.order_amount}`}
                    secondaryTypographyProps={{ variant: "caption" }}
                  />
                </div>
                <Stack alignItems="center" direction="row" spacing={0}>
                  <Button
                    onClick={() =>
                      printReport(
                        componentRef,
                        `${
                          value === 0
                            ? `List of Deliveries of an LSO Order`
                            : `List of Payments of an LSO Order`
                        }`
                      )
                    }
                    color="secondary"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <LocalPrintshopIcon />
                      </SvgIcon>
                    }
                  >
                    Print
                  </Button>
                  <ExportTableButton
                    componentRef={componentRef}
                    pageTittle="LSO Service"
                  />
                </Stack>
              </Stack>
              <Box sx={{ display: "flex" }}>
                <div>
                  <AddDelivery purchaseOrderData={order} type={"lso"} />
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <AddPayment
                    page={page}
                    rowsPerPage={rowsPerPage}
                    purchaseOrderData={order}
                  />
                </div>
              </Box>
            </Stack>

            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Deliveries" />
                <Tab label="Payments" />
              </Tabs>
            </Box>
            <Box sx={{ padding: "0 20px" }}>
              {value === 0 && (
                <DeliveriesTable
                  totals={deliveryTotals}
                  items={servicesDeliveries}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  componentRef={componentRef}
                />
              )}
              {value === 1 && (
                <PaymentTable
                  totals={paymentsTotals}
                  items={servicesPayments}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  isLoading={isLoading}
                  componentRef={componentRef}
                />
              )}
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default LsoOrder;
