import { Button, SvgIcon } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";

export const ExportTableButton = ({ componentRef, pageTittle }) => {
  const exportTableToExcel = () => {
    const tableHtml = componentRef.current.innerHTML; // Get the HTML content of the table

    // Validate table presence before processing
    if (!tableHtml) {
      console.error("Error: Table element not found in componentRef.");
      return; // Handle error gracefully, e.g., show an alert to the user
    }

    // Create a new Blob object with the HTML content
    const blob = new Blob([tableHtml], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${pageTittle}.xlsx`;

    // Trigger file download
    downloadLink.click();
    URL.revokeObjectURL(blob); // Revoke created object URL (important!)
  };

  return (
    <Button
      onClick={exportTableToExcel}
      color="secondary"
      startIcon={
        <SvgIcon fontSize="small">
          <FileOpenIcon />
        </SvgIcon>
      }
    >
      Export
    </Button>
  );
};
