import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Box } from "@mui/material";
export default function BasicDatePicker({
  label,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) {
  const formatDate = (date) => {
    const year = date.$y;
    const month = date.$M + 1 < 10 ? `0${date.$M + 1}` : date.$M + 1;
    const day = date.$D < 10 ? `0${date.$D}` : date.$D;
    return `${year}-${month}-${day}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(formatDate(date))}
            views={["year", "month", "day"]}
            label="From Date"
            sx={{ maxWidth: "150px", minWidth: "120px" }}
          />
          <span style={{ margin: "0 10px", textAlign: "center" }}>-</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(formatDate(date))}
            views={["year", "month", "day"]}
            label="To Date"
            sx={{ maxWidth: "150px", minWidth: "120px" }}
          />
        </Box>
      </DemoItem>
    </LocalizationProvider>
  );
}
