// AllocationTable component
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Box,
  Card,
  Checkbox,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../../components/scrollbar";
import ProgressBars from "../../../utils/loading";
import { useNavigate } from "react-router-dom";
import { DateTimeFormateTimeStamp } from "../../../utils/dateTime-fomater";

export const IssuedItemTabTable = (props) => {
  const {
    count = 0,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;

  const selectedSome = selected.length > 0 && selected.length < items.length;
  const selectedAll = items.length > 0 && selected.length === items.length;

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/student-allocation/${id}`);
  };
  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>quantity</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Student ADM</TableCell>
                <TableCell>Student Class</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Allocated By</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <ProgressBars />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {items.map((item, index) => {
                  const rowNum = page * rowsPerPage + index + 1; // Calculate the row number

                  return (
                    <TableRow
                      hover
                      key={item.student.student_number}
                      onClick={() =>
                        handleNavigate(item.student.student_number)
                      }
                    >
                      <TableCell>{rowNum}</TableCell>
                      <TableCell>{item.product.item_name}</TableCell>
                      <TableCell>{Math.abs(item.quantity)}</TableCell>
                      <TableCell>{item.student.student_name}</TableCell>
                      <TableCell>{item.student.student_number}</TableCell>
                      <TableCell>{`${item.student?.form} ${item.student?.stream}`}</TableCell>
                      <TableCell>
                        {DateTimeFormateTimeStamp(item.timestamp)}
                      </TableCell>
                      <TableCell>{item.allocated_by.full_name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

IssuedItemTabTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
