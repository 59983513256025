import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  SvgIcon,
} from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import * as endpoints from "../../utils/constants";
import { CreateItem } from "../../utils/fetchData";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSupplier({ type, supplierData }) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(() => {
    const initialData = {
      company_name: supplierData?.supplier.company_name || "",
      full_name: supplierData?.supplier.full_name || "",
      user_type: supplierData?.supplier.user_type || "SUPPLIER",
      phone_number: supplierData?.supplier.phone_number || "",
      physical_address: supplierData?.supplier.physical_address || "",
      company_registration_number:
        supplierData?.supplier.company_registration_number || "N/A",
      notes: supplierData?.supplier.notes || "",
    };

    if (supplierData?.supplier?.id) {
      initialData.id = supplierData.supplier.id;
    }

    return initialData;
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    // Check if any required field in formData is empty
    for (const field in formData) {
      // Exclude the 'notes' field from the check
      if (field !== "notes" && !formData[field] && !supplierData) {
        toast.error("Please fill in all required fields.");
        return;
      }
    }

    // If all required fields are filled, call CreateItem function
    CreateItem(formData, endpoints.NEW_SUPPLIER_ENDPOINT);
  };

  React.useEffect(() => {
    if (type === "edit-supplier" && supplierData) {
      setFormData({
        company_name: supplierData.supplier.company_name || "",
        full_name: supplierData.supplier.full_name || "",
        id: supplierData.supplier.id || "",
        phone_number: supplierData.supplier.phone_number || "",
        physical_address: supplierData.supplier.physical_address || "",
        company_registration_number:
          supplierData.supplier.company_registration_number || "",
        notes: supplierData.supplier.notes || "",
      });
      handleClickOpen();
    }
  }, [type, supplierData]);

  return (
    <React.Fragment>
      {type === "edit-supplier" ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={handleClickOpen}
        >
          Add Supplier
        </Button>
      )}

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {type === "edit-supplier" ? "Edit the Supplier" : "Add Supplier"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    type === "edit-supplier"
                      ? "edit Supplier"
                      : "create a supplier"
                  }`}
                  title={
                    type === "edit-supplier"
                      ? "Edit Supplier"
                      : "Add a supplier"
                  }
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      label: "Company name",
                      placeholder: "",
                      field: "company_name",
                    },
                    {
                      label: "Supplier Name",
                      placeholder: "",
                      field: "full_name",
                    },
                    {
                      label: "Phone Number",
                      placeholder: "",
                      field: "phone_number",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.label}
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *{" "}
                          </span>
                        </p>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder={inputField.placeholder}
                          value={formData[inputField.field]}
                          onChange={(e) =>
                            handleInputChange(inputField.field, e.target.value)
                          }
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      label: " Company Location",
                      placeholder: "",
                      field: "physical_address",
                    },
                    {
                      label: "Company Registration Number",
                      placeholder: "",
                      field: "company_registration_number",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.label}
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *{" "}
                          </span>
                        </p>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder={inputField.placeholder}
                          value={formData[inputField.field]}
                          onChange={(e) =>
                            handleInputChange(inputField.field, e.target.value)
                          }
                        />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
