// PaymentTable component
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Grid,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { DateTimeFormateTimeStamp } from "../../utils/dateTime-fomater";
import { useNavigate } from "react-router-dom";
import { SeverityPill } from "../../components/severity-pill";
import { formatDisplayNumber } from "../../utils/number-formter";
import React, { useState } from "react";
import ImageViewer from "../../components/image-popup";
import AddDelivery from "./add-deliveries";

export const DeliveriesTable = (props) => {
  const {
    totals = {},
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleNavigate = (payment) => {
    const { id } = payment;
    navigate(`/purchase-order/${id}`);
  };

  const [openEdit, setOpenEdit] = React.useState({
    open: false,
    data: null,
    type: "",
  });

  const handleEditClick = (event, delivery) => {
    event.stopPropagation();
    const type =
      delivery.delivery.purchase_order.product.is_tangible &&
      delivery.delivery.purchase_order.product.unit_of_measurement
        ? "lpo"
        : "lso";
    setOpenEdit({ open: true, data: delivery, type: type });
  };

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>LPO Number</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>quantity</TableCell>
                <TableCell>Invoiced Amount</TableCell>
                <TableCell>Total Delivered </TableCell>
                <TableCell>In Stock</TableCell>
                <TableCell>Delivered By</TableCell>
                <TableCell>Received By</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Date Delivered</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <CustomNoRowsOverlay />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {items.map((delivery, index) => {
                  const isSelected = selected.includes(delivery.delivery.id);
                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow
                      hover
                      key={delivery.id}
                      selected={isSelected}
                      onClick={() =>
                        handleNavigate(delivery.delivery.purchase_order)
                      }
                    >
                      <TableCell>{rowNum}</TableCell>
                      <TableCell sx={{ padding: "0px" }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <span>
                                {delivery.delivery.purchase_order.order_number}
                              </span>
                            </React.Fragment>
                          }
                          primaryTypographyProps={{ variant: "subtitle1" }}
                          secondary={
                            delivery.delivery.delivery_image && (
                              <ImageViewer
                                imageUrl={delivery.delivery.delivery_image}
                                tittle={"delivery image"}
                                open={open}
                                setOpen={setOpen}
                              />
                            )
                          }
                          secondaryTypographyProps={{ variant: "caption" }}
                        />
                      </TableCell>

                      <TableCell sx={{ typography: "subtitle1" }}>
                        {delivery.delivery.purchase_order.product.item_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: "capitalize",
                          padding: 0,
                        }}
                      >
                        <SeverityPill color="success">
                          {`${delivery.delivery.quantity}
                        ${delivery.delivery.purchase_order.product.unit_of_measurement} `}
                        </SeverityPill>
                      </TableCell>

                      <TableCell>
                        <ListItemText
                          primary={
                            <SeverityPill color="warning">
                              {` KES. ${formatDisplayNumber(
                                delivery.delivery.invoice_amount
                              )}`}
                            </SeverityPill>
                          }
                          primaryTypographyProps={{ variant: "subtitle1" }}
                          secondary={
                            delivery.delivery.invoice_image && (
                              <ImageViewer
                                imageUrl={delivery.delivery.invoice_image}
                                tittle={"invoice image"}
                                open={open}
                                setOpen={setOpen}
                              />
                            )
                          }
                          secondaryTypographyProps={{ variant: "caption" }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {`${
                          delivery.delivery.current_total_delivered +
                          delivery.delivery.quantity
                        }
                        ${
                          delivery.delivery.purchase_order.product
                            .unit_of_measurement
                        } `}
                      </TableCell>

                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {`${formatDisplayNumber(
                          delivery.delivery.current_stock_qty +
                            delivery.delivery.quantity
                        )}`}
                      </TableCell>

                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        {delivery.delivery.delivered_by}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        {delivery.delivery.processed_by.full_name}
                      </TableCell>

                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                        sx={{ padding: "0px" }}
                      >
                        <ListItemText
                          primary={`${delivery.delivery.purchase_order.supplier.full_name}- ${delivery.delivery.purchase_order.supplier.phone_number}`}
                          primaryTypographyProps={{ variant: "subtitle1" }}
                          secondary={
                            delivery.delivery.purchase_order.supplier
                              .company_name
                          }
                          secondaryTypographyProps={{ variant: "caption" }}
                        />
                      </TableCell>

                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        {DateTimeFormateTimeStamp(delivery.delivery.timestamp)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => handleEditClick(event, delivery)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginLeft: 16 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            Total Invoiced Amount:{" "}
            <SeverityPill color="warning">
              {` ksh. ${formatDisplayNumber(totals.total_invoice_amount)}`}
            </SeverityPill>
          </Typography>
        </Grid>

        <TablePagination
          component="div"
          count={totals.total}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Grid>
      {openEdit.open && (
        <div style={{ display: "none" }}>
          <AddDelivery mode="edit" type={openEdit.type} data={openEdit.data} />
        </div>
      )}
    </Card>
  );
};

DeliveriesTable.propTypes = {
  totals: PropTypes.object,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
