import {PRODUCTS_ENDPOINT,} from "../../utils/constants";
import {BaseSearchBox} from "../search-box";
import {LsoSortFilters} from "../../utils/constants-types";

export const LsoSearch = (
    {
        data,
        setData,
        setIsLoading,
        setTotals,
        payload,
    }
) => {
    return BaseSearchBox({
        placeholder: "Enter category or item name...",
        endpoint: PRODUCTS_ENDPOINT,
        data: data,
        setData: setData,
        setTotals: setTotals,
        setIsLoading: setIsLoading,
        payload: payload,
        sortFilters: LsoSortFilters,
    });
};
