import {
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {makeNetworkCall} from "../../utils/fetchData";
import { toast } from "react-toastify";
import {STREAMS_ENDPOINT} from "../../utils/constants";

export const StudentAllocationsSearch = ({
  inputData,
  data,
  setInputData,
  setData,
  getStudent,
  student,
  setStudent,
  setIsLoading,
}) => {
  const [streams, setStreams] = useState([]);

  const [error, setError] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const handleStudentChange = (e) => {
    const inputStudent = e.target.value.replace(/\D/g, "");
    setStudent(inputStudent);
  };

  const handleEditClick = () => {
    setDisabledInput(false);
  };

  const handleStudentClick = async () => {
    if (student.length === 0) {
      setError(true);
      return;
    }

    try {
      await getStudent();
      setDisabledInput(true);
      setDisabledBtn(true);
    } catch (error) {
      toast.log(error);
    }
  };

  useEffect(() => {
    setDisabledBtn(student.length === 0);
  }, [student]);

  useEffect(() => {
    const fetchStreamsData = async () => makeNetworkCall(
        STREAMS_ENDPOINT,
        {
            form: "all",
        },
        setStreams,
        null,
        setIsLoading,
        {},
        "POST",
        setInputData,
        inputData
    );

    fetchStreamsData();
  }, [inputData.forms]);

  const handleClearSearch = () => {
    setStudent("");
    setData(data);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleStudentClick();
    }
  };
  const handleInputChange = (prop) => (event) => {
    setInputData({ ...inputData, [prop]: event.target.value });
  };

  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="form-label">Form</InputLabel>
            <Select
              labelId="form-label"
              id="form-select"
              label="Form"
              value={inputData.forms}
              onChange={handleInputChange("forms")}
            >
              <MenuItem value={1}>Form 1</MenuItem>
              <MenuItem value={2}>Form 2</MenuItem>
              <MenuItem value={3}>Form 3</MenuItem>
              <MenuItem value={4}>Form 4</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="stream-label">Stream</InputLabel>
            <Select
              labelId="stream-label"
              id="stream-select"
              label="Stream"
              value={inputData.selectedStream} // Changed from `inputData.stream` to `inputData.selectedStream`
              onChange={handleInputChange("selectedStream")} // Pass the correct property name to handleInputChange
            >
              {streams.map((stream) => (
                <MenuItem key={stream.id} value={stream}>
                  {stream}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid sx={{ display: "flex" }} item xs={12} sm={12} md={4} lg={2}>
          <div className="student_container">
            <TextField
              className="form_input"
              style={{ minWidth: "300px", width: "100%" }}
              id="outlined-basic"
              label="Enter admission no. *"
              variant="outlined"
              value={student}
              onChange={handleStudentChange}
              disabled={disabledInput}
              helperText={error && "Student with that admission was not found"}
              error={error}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {disabledInput && (
                      <InputAdornment onClick={handleEditClick} position="end">
                        <div className="phone_container_p">
                          <p>Edit</p>
                        </div>
                      </InputAdornment>
                    )}
                    {!disabledInput && (
                      <Button
                        variant="contained"
                        disabled={disabledBtn}
                        onClick={handleStudentClick}
                      >
                        Continue
                      </Button>
                    )}
                  </React.Fragment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
