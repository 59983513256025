import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Scrollbar } from "../../components/scrollbar";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";

export const SideNav = (props) => {
  const [filteredItems, setFilteredItems] = useState([]);

  const storedToken = localStorage.getItem("userData");

  // If you want to parse it back to an object
  const userData = storedToken ? JSON.parse(storedToken) : null;

  if (userData && userData.school && userData.school.school_prefix) {
    const schoolPrefix = userData.school.school_prefix;

    // Check if school_prefix is a two-word string
    const isTwoWords = schoolPrefix.includes(" ");

    if (isTwoWords) {
      // Join the two words with an underscore
      userData.school.school_prefix = schoolPrefix.replace(/\s+/g, "_");
      localStorage.setItem("userData", JSON.stringify(userData));
    }
  }

  const { open, onClose } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const userRole = userData.user.user_role;

  useEffect(() => {
    // Filter items based on userRole
    const filtered = items.filter((item) => {
      return item.roles.includes(userRole);
    });
    setFilteredItems(filtered);
  }, [userRole]);

  // Retrieve isDarkMode from local storage only if localStorage is available
  const isLocalStorageAvailable = typeof localStorage !== "undefined";

  const isDarkMode = isLocalStorageAvailable
    ? localStorage.getItem("isDarkMode") === "true"
    : false;
  const backgroundPaper = isDarkMode ? "#0A2449" : "neutral.800";

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };
  const content = (
    <Box
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.04)",
              borderRadius: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              p: "12px",
            }}
          >
            <div>
              <Typography color="inherit" variant="subtitle1">
                Mzazilink
              </Typography>
              <Typography color="neutral.400" variant="body2">
                Procurement
              </Typography>
            </div>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "neutral.700" }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {filteredItems.map((item) => {
              const active = item.path ? pathname === item.path : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: "neutral.700" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Box
            onClick={() => handleNavigate()}
            sx={{
              display: "inline-flex",
              backgroundColor: "rgba(255, 255, 255, 0.04)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
              // Additional styles for the img element
              "& img": {
                maxWidth: "100%",
                maxHeight: "100%",
              },
            }}
          >
            <img
              alt="School logo"
              src={`https://mzazilink.com/school_logos/${userData?.school?.school_prefix}.png`}
            />
          </Box>
          <Typography
            sx={{ textAlign: "center" }}
            color="neutral.100"
            variant="subtitle2"
          >
            {userData?.school?.school_name}
          </Typography>
          <Typography
            sx={{ textAlign: "center" }}
            color="neutral.500"
            variant="caption"
          >
            {userData?.school?.email_address}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: backgroundPaper,
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: backgroundPaper,
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
