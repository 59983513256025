export const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-US");
  if (
    number === 0 ||
    number === undefined ||
    number === "" ||
    number === "NaN"
  ) {
    return "";
  } else {
    return formatter.format(number);
  }
};
export const formatDisplayNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
};

export const clearFormatNumber = (number) => {
  const numberStr = number.toString();
  return parseFloat(numberStr.replace(/,/g, ""));
};

export const handleNumberInputChange = (field, value, setFormValues) => {
  setFormValues((prevValues) => ({
    ...prevValues,
    [field]: value.replace(/,/g, ""),
  }));
};

export const formatAmount = (number) => {
  if (number < 10000) {
    return new Intl.NumberFormat().format(number);
  } else if (number < 1000000) {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(number / 1000);
    return `${formattedNumber}k`;
  } else if (number < 1000000000) {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(number / 1000000);
    return `${formattedNumber}M`;
  } else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
    }).format(number / 1000000000);
    return `${formattedNumber}B`;
  }
};
