import {
  format,
  parseISO,
} from "date-fns";

export const NormalDateTimeFormate = (dateString) => {
  const parsedDate = new Date(dateString);

  return format(parsedDate, "dd MMM yyyy");
};

export const DateTimeFormate = (dateTime) => {
  const parsedDate = parseISO(dateTime);

   return format(parsedDate, "d/MM/y");
   //return format(parsedDate, "d/MM/y h:mm");
};

export const DateTimeFormateTimeStamp = (timestamp) => {
  const parsedDate = new Date(timestamp * 1000);
  return format(parsedDate, "d/MM/y");
};
