import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { CreateItem, fetchDepartmentSettingsData } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import { formatNumber } from "../../utils/number-formter";
import ProductsSelection from "../products/products-configs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddService({ mode, data, supplierData }) {
  const supplier = supplierData || data.order.supplier;
  const [open, setOpen] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [orders, setOrders] = useState([
    { product_id: "", quantity: "", order_amount: "" },
  ]);
  const [unitOfMeasurements, setUnitOfMeasurements] = useState(
    data.order.product.unit_of_measurement || []
  );
  const [commonValues, setCommonValues] = React.useState(() => {
    const initialData = {
      order_number: "",
      requisition_number: "N/A",
      is_tendered: false,
      mode_of_payment: "",
      requesting_department: "",
    };

    if (data?.order?.id) {
      initialData.id = data?.order?.id;
    }

    return initialData;
  });

  useEffect(() => {
    if (data) {
      // Initialize formValues with data
      setCommonValues({
        order_number: data.order.order_number || "",
        requisition_number: data.order.requisition_number || "",
        is_tendered: data.order.is_tendered ? "Yes" : "No",
        mode_of_payment: data.order.product.unit_of_measurement || "",
        requesting_department: data.order.requesting_department || "",
        notes: data.order.notes || "",
        expected_by_date: data.order.expected_by_date || "",
        id: data.order.id || "",
      });

      // Initialize orders array based on data
      const initialOrders = [
        {
          product_id: data.order.product.id || "",
          quantity: data.order.quantity || "",
          order_amount: data.order.order_amount || "",
        },
      ];
      setOrders(initialOrders);
    }

    handleClickOpen();
  }, [data]);

  useEffect(() => {
    fetchDepartmentSettingsData(setDepartment);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCommonInputChange = (field, value) => {
    setCommonValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleOrderInputChange = (index, field, value) => {
    const updatedOrders = [...orders];
    updatedOrders[index][field] = value;
    setOrders(updatedOrders);
  };

  const handleProductChange = (index, unitOfMeasurement) => {
    const updatedUnitOfMeasurements = [...unitOfMeasurements];
    updatedUnitOfMeasurements[index] = unitOfMeasurement;
    setUnitOfMeasurements(updatedUnitOfMeasurements);
  };

  const handleAddOrder = () => {
    setOrders([...orders, { product_id: "", quantity: "", order_amount: "" }]);
    setUnitOfMeasurements([...unitOfMeasurements, ""]);
  };

  const handleRemoveOrder = (index) => {
    const updatedOrders = orders.filter((_, i) => i !== index);
    const updatedUnitOfMeasurements = unitOfMeasurements.filter(
      (_, i) => i !== index
    );
    setOrders(updatedOrders);
    setUnitOfMeasurements(updatedUnitOfMeasurements);
  };

  const handleSubmit = async () => {
    // Validation checks
    if (
      !commonValues.order_number ||
      !commonValues.requisition_number ||
      !commonValues.requesting_department ||
      !supplier.id ||
      !data
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Collect data to be posted

    // Concatenate order data into comma-separated strings
    const concatenatedData = orders.reduce(
      (acc, order) => {
        Object.keys(order).forEach((key) => {
          acc[key] = acc[key] ? `${acc[key]}, ${order[key]}` : order[key];
        });
        return acc;
      },
      { ...commonValues, supplier_id: supplier.id }
    );

    // Create item for each order
    await CreateItem(concatenatedData, endpoints.NEW_ORDER_ENDPOINT);

    handleClose();
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        CREATE AN LSO ORDER
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    mode === "edit" ? "edit" : "create"
                  }  create an LSO orders`}
                  title={`${
                    mode === "edit" ? "EDIT" : "CREATE"
                  } CREATE AN LSO ORDER FOR ${supplier?.company_name}`}
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItem>
                    <FormControl fullWidth>
                      <p>
                        Requesting Department
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *{" "}
                        </span>
                      </p>
                      <Autocomplete
                        options={
                          department?.departments
                            ? department?.departments?.split(",")
                            : []
                        }
                        value={commonValues?.requesting_department}
                        onChange={(event, value) =>
                          handleCommonInputChange(
                            "requesting_department",
                            value
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                  {[
                    {
                      labelName: "Expected By Date",
                      type: "date",
                      field: "expected_by_date",
                    },
                    {
                      labelName: "  LPO/LSO Number",
                      field: "order_number",
                    },
                    {
                      labelName: "Requisition  Number",
                      field: "requisition_number",
                    },
                    // {
                    //   labelName: "Narration (optional) ",
                    //   field: "notes",
                    // },
                  ].map((inputField, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <p>
                        {inputField.labelName}
                        {inputField.field !== "notes" && (
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *{" "}
                          </span>
                        )}
                      </p>
                      {inputField.type === "date" ? (
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              handleCommonInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            defaultValue=""
                            value={commonValues[inputField.field]}
                            fullWidth
                            onChange={(e) =>
                              handleCommonInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      )}
                    </ListItem>
                  ))}
                  {/* <ListItem>
                    <FormControl component="fieldset">
                      <p>
                        Is Tendered
                        <span style={{ color: "red" }}> *</span>
                      </p>
                      <RadioGroup
                        row
                        value={commonValues["is_tendered"]}
                        onChange={(e) =>
                          handleCommonInputChange("is_tendered", e.target.value)
                        }
                      >
                        {[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ].map((option, optionIndex) => (
                          <FormControlLabel
                            key={optionIndex}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </ListItem> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  {orders.map((order, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      <ProductsSelection
                        index={index}
                        setOrderValues={handleOrderInputChange}
                        setProduct={handleProductChange}
                        initialProduct={data?.order?.product}
                      />
                      {[
                        {
                          labelName: `Quantity${unitOfMeasurements[index]}`,
                          field: "quantity",
                        },
                        {
                          labelName: "Total Order Amount",
                          field: "order_amount",
                        },
                      ].map((inputField, inputIndex) => (
                        <ListItem
                          key={inputIndex}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <p>
                            {inputField.labelName}
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *{" "}
                            </span>
                          </p>
                          <FormControl sx={{ width: "100%" }}>
                            <OutlinedInput
                              value={formatNumber(order[inputField.field])}
                              fullWidth
                              onChange={(e) =>
                                handleOrderInputChange(
                                  index,
                                  inputField.field,
                                  e.target.value.replace(/,/g, "")
                                )
                              }
                            />
                          </FormControl>
                        </ListItem>
                      ))}
                      {orders.length > 1 && (
                        <Button
                          color="secondary"
                          onClick={() => handleRemoveOrder(index)}
                          sx={{ marginTop: "10px" }}
                        >
                          Remove Service
                        </Button>
                      )}
                      <Button
                        color="secondary"
                        onClick={() => handleRemoveOrder(index)}
                        sx={{ marginTop: "10px" }}
                      >
                        Remove Service
                      </Button>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={handleAddOrder}
                    sx={{ marginTop: "10px" }}
                  >
                    Add Another Service
                  </Button>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
