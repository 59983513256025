import {useMemo} from "react";
import {useSelection} from "../../hooks/use-selection";
import {SCHOOL_ORDERS_ENDPOINT} from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import {ServiceTable} from "../../sections/lsoOrders/lsoOrder-table";
import {ServiceSearch} from "../../sections/lsoOrders/search-lsoOrder";
import {useLoadData} from "../../services/featching-data-service";

// export const useLsoService = (page, rowsPerPage, active_orders = "false") => {
//   const [data, setData] = useState([]);
//   const [totals, setTotals] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const params = {
//     offset: page * rowsPerPage,
//     per_page: rowsPerPage,
//     product_type: "lso",
//   };
//   useEffect(() => {
//     const fetch = async () =>
//       makeNetworkCall(
//         SCHOOL_ORDERS_ENDPOINT,
//         params,
//         setData,
//         setTotals,
//         setIsLoading,
//         {}
//       );
//     fetch();
//   }, [page, rowsPerPage, active_orders]);

//   return { data, totals, isLoading, setTotals, setData, setIsLoading };
// };

const useServiceIds = (services) => {
    return useMemo(() => {
        return services?.map((order) => order.id);
    }, [services]);
};

const LsoOrderPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();

    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
        product_type: "lso",
    };

    const {
        data: services,
        totals,
        isLoading,
        setTotals,
        setData,
        setIsLoading,
    } = useLoadData(payload, SCHOOL_ORDERS_ENDPOINT);
    const serviceIds = useServiceIds(services);
    const servicesSelection = useSelection(serviceIds);

    return BasePage(
        "LSO Orders",
        null,
        <ServiceSearch
            data={services}
            setIsLoading={setIsLoading}
            setData={setData}
            setTotals={setTotals}
            payload={payload}
            baseEndpoint={SCHOOL_ORDERS_ENDPOINT}
        />,
        <ServiceTable
            totals={totals}
            items={services}
            onDeselectAll={servicesSelection.handleDeselectAll}
            onDeselectOne={servicesSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={servicesSelection.handleSelectAll}
            onSelectOne={servicesSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={servicesSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef
    );
};

export default LsoOrderPage;
