import {
  SCHOOL_SUPPLIERS_ENDPOINT,
} from "../../utils/constants";
import {BaseSearchBox} from "../search-box";
import { SuppliersSortFilters } from "../../utils/constants-types";

export const SuppliersSearch = ({
  data,
  setData,
  setIsLoading,
  setTotals,
  payload,
}) => {
  return BaseSearchBox({
    placeholder: "Enter name, phone number or company name...",
    endpoint: SCHOOL_SUPPLIERS_ENDPOINT,
    data: data,
    setData: setData,
    setTotals: setTotals,
    setIsLoading: setIsLoading,
    payload: payload,
    sortFilters: SuppliersSortFilters,
  });
};
