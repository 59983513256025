import React from "react";
import BasePageConfigs from "../../pages/page-configs";
import {
  AppBar,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Box,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProgressBars from "../../utils/loading";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { useLoadData } from "../../services/featching-data-service";
import { GROUPED_SCHOOL_ORDERS_ENDPOINT } from "../../utils/constants";
import { SeverityPill } from "../../components/severity-pill";
import { useNavigate } from "react-router-dom";

const PurchaseOrderItems = ({
  dialogOpen,
  setDialogOpen,
  selectedOrder,
  setSelectedOrder,
}) => {
  const navigate = useNavigate();
  const { page, rowsPerPage } = BasePageConfigs();
  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    order_number: selectedOrder ? selectedOrder.order.order_number : "",
  };

  const { data, isLoading } = useLoadData(
    payload,
    GROUPED_SCHOOL_ORDERS_ENDPOINT
  );

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  const handleNavigate = (product) => {
    const { id, item_name } = product;

    navigate(`/inventory/${id}/${item_name.replace("/", "|")}`);
  };
  return (
    <Dialog maxWidth="xl" open={dialogOpen} onClose={handleCloseDialog}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {data[0]?.order?.supplier?.company_name?.toUpperCase()}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCloseDialog}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ marginTop: "1rem", minWidth: "60vw" }}>
        <DialogContent>
          <Card>
            <Box sx={{ minWidth: 800, minHeight: 400 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Name</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: 200,
                            }}
                          >
                            <ProgressBars />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : data.length === 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: 200,
                            }}
                          >
                            <CustomNoRowsOverlay />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {data.map((order, index) => {
                        return (
                          <TableRow key={order?.order?.product?.id} hover>
                            <TableCell>
                              <ListItemText
                                primary={order?.order?.product?.item_name}
                                primaryTypographyProps={{
                                  variant: "subtitle1",
                                }}
                                secondary={order?.order?.product?.category}
                                secondaryTypographyProps={{
                                  variant: "caption",
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              {order?.order?.quantity || "N/A"}{" "}
                              {order?.order?.product?.unit_of_measurement}
                            </TableCell>

                            <TableCell>
                              <SeverityPill color="warning">
                                {`KSH. ${order?.order?.order_amount || "N/A"}`}
                              </SeverityPill>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  handleNavigate(order?.order?.product)
                                }
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Card>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default PurchaseOrderItems;
