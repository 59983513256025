import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {IMAGE_URL} from "../utils/constants";

export default function ImageViewer({imageUrl, tittle}) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const handleClickOpen = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    const Image_url = `${IMAGE_URL}${imageUrl}`;

    return (
        <React.Fragment>
            <Typography
                variant="p"
                component="span"
                sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "primary.main",
                    "&:hover": {
                        color: "primary.dark",
                        textDecoration: "underline",
                    },
                }}
                onClick={handleClickOpen}
            >
                view {tittle}
            </Typography>
            <Dialog
                fullWidth={true}
                open={open}
                maxWidth="xl"
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {`${tittle}`}
                </DialogTitle>
                <DialogContent
                    sx={{
                        minWidth: "60vw",
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "60vh",
                    }}
                >
                    <img
                        style={{objectFit: "contain", minHeight: "10vh"}}
                        src={Image_url}
                        alt={tittle}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        CLose
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
