import { useCallback, useEffect, useMemo, useState } from "react";
import { withAuthGuard } from "../../hocs/with-auth-guard";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { styled } from "@mui/material/styles";
import { items } from "./config";
import { useLocation } from "react-router-dom";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const Layout = withAuthGuard((props) => {
  const { children } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const [openNav, setOpenNav] = useState(false);
  const storedToken =
    typeof window !== "undefined" ? localStorage.getItem("userData") : null;
  const user = storedToken ? JSON.parse(storedToken) : null;

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(() => {
    handlePathnameChange();
  }, [pathname]);

  const checkRoles = (requiredRoles) => {
    return user && user.user && requiredRoles.includes(user.user.user_role);
  };

  const renderContent = useMemo(() => {
    const requiredRoles =
      items.find((item) => item.path === pathname)?.roles || [];

    if (requiredRoles.length === 0 || checkRoles(requiredRoles)) {
      return <LayoutContainer>{children}</LayoutContainer>;
    } else {
      return <p>Unauthorized access this Page</p>;
    }
  }, [children, pathname, checkRoles]);
  return (
    <>
      <TopNav onNavOpen={() => setOpenNav(true)} />
      <SideNav onClose={() => setOpenNav(false)} open={openNav} />
      <LayoutRoot>{renderContent}</LayoutRoot>
    </>
  );
});
