import {useMemo} from "react";
import {useSelection} from "../../hooks/use-selection";
import BasePage from "../page-blueprint";
import BasePageConfigs from "../page-configs";
import AddServiceProvider from "../../sections/serviceProviders/add-service-provider";
import {ServiceProviderSearch} from "../../sections/serviceProviders/service-provider-search";
import {ServiceProviderTable} from "../../sections/serviceProviders/service-provider-table";
import {useLoadData} from "../../services/featching-data-service";
import {SCHOOL_SUPPLIERS_ENDPOINT} from "../../utils/constants";

const useServiceProviderIds = (serviceProviders) => {
    return useMemo(() => {
        return serviceProviders.map((serviceProvider) => serviceProvider.id);
    }, [serviceProviders]);
};

const ServiceProvidersPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();

    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
        user_type: "SERVICE-PROVIDER",
    };

    const {
        data: serviceProviders,
        totals,
        isLoading,
        setTotals,
        setIsLoading,
        setData,
    } = useLoadData(payload, SCHOOL_SUPPLIERS_ENDPOINT);
    const serviceProviderIds = useServiceProviderIds(serviceProviders);
    const serviceProvidersSelection = useSelection(serviceProviderIds);

    return BasePage(
        "School Service Providers",
        <AddServiceProvider
            setData={setData}
            setIsLoading={setIsLoading}
            page={page}
            rowsPerPage={rowsPerPage}
        />,
        <ServiceProviderSearch
            data={serviceProviders}
            setData={setData}
            setTotals={setTotals}
            setIsLoading={setIsLoading}
            payload={payload}
        />,
        <ServiceProviderTable
            totals={totals}
            items={serviceProviders}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={serviceProvidersSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef
    );
};

export default ServiceProvidersPage;
