// main file
import { createTheme as createMuiTheme } from "@mui/material";
import { createPalette } from "./create-palette";
import { createComponents } from "./create-components";
import { createShadows } from "./create-shadows";
import { createTypography } from "./create-typography";

export function createTheme() {
  const isLocalStorageAvailable = typeof localStorage !== "undefined";

  // Retrieve isDarkMode from local storage only if localStorage is available
  const isDarkMode = isLocalStorageAvailable
    ? localStorage.getItem("isDarkMode") === "true"
    : false;

  const palette = createPalette(isDarkMode);
  const components = createComponents({ palette, isDarkMode });
  const shadows = createShadows();
  const typography = createTypography();

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    components,
    palette,
    shadows,
    shape: {
      borderRadius: 8,
    },
    typography,
  });
}
