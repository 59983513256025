import { Box, Card, Stack, Typography } from "@mui/material";

export const printReport = (componentRef, pageTitle) => {
  const printWindow = window.open(
    "#/print_report",
    "_blank",
    "toolbar=0,location=0,menubar=0,rel=noreferrer"
  );
  printWindow.reportBody = componentRef.current.innerHTML;
  printWindow.reportTitle = pageTitle;
};

export const PrintDocument = () => {
  const tittle = window.reportTitle;
  const body = window.reportBody;
  const storedToken = localStorage.getItem("userData");

  // If you want to parse it back to an object
  const userData = storedToken ? JSON.parse(storedToken) : null;

  try {
    window.onafterprint = window.close;
    window.print();
  } catch (e) {
    window.onload = window.print;
  }
  return (
    <Card
      sx={{
        background: "white",
        color: "black",
        minHeight: "100vh",
        borderRadius: "1px",
      }}
      className="printDocument"
    >
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            alt="School logo"
            src={`https://mzazilink.com/school_logos/${userData?.school?.school_prefix}.png`}
          />
          <Box>
            <Typography variant="h3">
              {userData?.school?.school_name}
            </Typography>
            <Typography variant="body2">
              {userData?.school?.postal_address}
            </Typography>
          </Box>
        </Box>
        <Stack>
          <Typography
            sx={{
              textAlign: "center",
              textTransform: "uppercase",
              textDecoration: "underline",
            }}
            variant="h5"
          >
            {tittle}
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{ minWidth: 800 }}
        dangerouslySetInnerHTML={{ __html: body }}
      ></Box>
    </Card>
  );
};
