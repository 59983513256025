import PropTypes from "prop-types";
import {
  Box,
  Card,
  Grid,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import { SeverityPill } from "../../components/severity-pill";
import { formatDisplayNumber } from "../../utils/number-formter";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { DateTimeFormate } from "../../utils/dateTime-fomater";
import React from "react";
import AddSupplier from "./add-service-provider";

export const ServiceProviderTable = (props) => {
  const navigate = useNavigate();
  const {
    totals = {},
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;

  const handleTableRowClick = (supplier) => {
    const { id, phone_number, full_name, company_name } = supplier.supplier;

    navigate(
      `/service-provider/${id}/${phone_number.replace(
        "/",
        "|"
      )}/${full_name.replace("/", "|")}/${company_name.replace("/", "|")}`
    );
  };

  const handleEditClick = (event, supplier) => {
    event.stopPropagation();
    // Pass supplier data to the AddSupplier component
    setOpenEditSupplier({ open: true, supplierData: supplier });
  };

  const [openEditSupplier, setOpenEditSupplier] = React.useState({
    open: false,
    supplierData: null,
  });

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Total Orders</TableCell>
                <TableCell>Total Paid</TableCell>
                <TableCell>Invoiced Amount</TableCell>
                <TableCell>Balance Amount</TableCell>
                <TableCell>Last Delivery Date</TableCell>
                <TableCell>Last Payment Date</TableCell>
                <TableCell>Registered Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <CustomNoRowsOverlay />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {items.map((supplier, index) => {
                  const isSelected = selected.includes(supplier.supplier.id);

                  const rowNum = page * rowsPerPage + index + 1; // Calculate the row number

                  return (
                    <TableRow
                      hover
                      key={supplier.supplier.id}
                      selected={isSelected}
                      onClick={() => handleTableRowClick(supplier)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{rowNum}</TableCell>
                      <TableCell>
                        <ListItemText
                          primary={`${supplier.supplier.company_name}`}
                          primaryTypographyProps={{
                            variant: "subtitle1",
                          }}
                          secondary={supplier.supplier.full_name}
                          secondaryTypographyProps={{
                            variant: "caption",
                          }}
                        />
                      </TableCell>
                      <TableCell>{supplier.orders_count}</TableCell>
                      <TableCell>
                        <SeverityPill color="warning">{`Ksh. ${formatDisplayNumber(
                          supplier.total_paid
                        )}`}</SeverityPill>
                      </TableCell>
                      <TableCell>
                        <SeverityPill color="secondary">{`Ksh. ${formatDisplayNumber(
                          supplier.invoiced_amount
                        )}`}</SeverityPill>
                      </TableCell>
                      <TableCell>
                        <SeverityPill color="error">{`Ksh. ${formatDisplayNumber(
                          supplier.invoiced_amount - supplier.total_paid
                        )}`}</SeverityPill>
                      </TableCell>
                      <TableCell>
                        {supplier.last_delivery_date.startsWith("No")
                          ? supplier.last_delivery_date
                          : supplier.last_delivery_date.substring(0, 10)}
                      </TableCell>
                      <TableCell>
                        {supplier.last_payment_date.startsWith("No")
                          ? supplier.last_payment_date
                          : supplier.last_payment_date.substring(0, 10)}
                      </TableCell>
                      <TableCell>
                        {DateTimeFormate(supplier.supplier.date_registered)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => handleEditClick(event, supplier)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginLeft: 16 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            Total Invoiced Amount:{" "}
            <SeverityPill color="warning">
              {` ksh. ${formatDisplayNumber(totals.total_invoice_amount)}`}
            </SeverityPill>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total Amount Paid:{" "}
            <SeverityPill color="success">
              {` ksh. ${formatDisplayNumber(totals.total_paid_amount)}`}
            </SeverityPill>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total Balance:{" "}
            <SeverityPill color="error">
              {` ksh. ${formatDisplayNumber(totals.total_balance_owed)}`}
            </SeverityPill>
          </Typography>
        </Grid>

        <TablePagination
          component="div"
          count={totals.total}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Grid>

      {openEditSupplier.open && (
        <div style={{ display: "none" }}>
          <AddSupplier
            type="edit-supplier"
            supplierData={openEditSupplier.supplierData}
          />
        </div>
      )}
    </Card>
  );
};

ServiceProviderTable.propTypes = {
  totals: PropTypes.object,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
