import { formatDisplayNumber } from "./number-formter";

export const printDocumentReport = (formatPrintData, type) => {
  const printWindow = window.open(
    "",
    "_blank",
    "toolbar=0,location=0,menubar=0,rel=noreferrer"
  );

  if (!printWindow) {
    alert("Please allow pop-ups for this website");
    return;
  }

  const storedToken = localStorage.getItem("userData");
  const userData = storedToken ? JSON.parse(storedToken) : null;
  const date = new Date().toLocaleDateString();
  const schoolPrefix = userData?.school?.school_prefix;
  const schoolName = userData?.school?.school_name;
  const schoolAddress = userData?.school?.postal_address;
  const schoolPhone = userData?.school?.phone_number;
  const schoolAdmin = userData?.user?.full_name;

  try {
    printWindow.document.open();

    const isCondensed = formatPrintData.data.length > 9;

    printWindow.document.write(`
      <html>
        <head>
          <title>Document Report</title>
          <style>
            :root {
              --font-size-body: ${isCondensed ? "10px" : "16px"};
              --font-size-heading: ${isCondensed ? "14px" : "18px"};
              --padding-cell: ${isCondensed ? "5px" : "10px"};
              --padding-table: ${isCondensed ? "10px" : "20px"};
              --margin-top-signature: ${isCondensed ? "10px" : "20px"};
              --margin-bottom-info: ${isCondensed ? "10px" : "20px"};
              --margin-right-info: ${isCondensed ? "0" : "10px"};
            }

            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: var(--padding-table);
            }
            h1 {
              text-align: center;
              margin-top: var(--margin-bottom-info);
              font-size: var(--font-size-heading);
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: var(--padding-table);
              font-size: var(--font-size-body);
            }
            th, td {
              border: 1px solid black;
              padding: var(--padding-cell);
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            .total-amount {
              text-align: right;
              font-weight: bold;
            }
            .company-info {
              text-align: center;
              margin-bottom: var(--margin-bottom-info);
              border-bottom: 2px solid black;
              padding-bottom: var(--margin-bottom-info);
            }
            .company-info img {
              height: ${isCondensed ? "40px" : "50px"};
              margin-right: 10px;
            }
            .signature-section {
              margin-top: var(--padding-table);
            }
            .signature-box {
              display: grid;
              grid-template-columns: auto auto auto;
              gap: var(--padding-cell);
              margin-top: var(--padding-cell);
              align-items: center;
              font-size: var(--font-size-body)
            }
            .signature-box > div {
              display: flex;
              align-items: center;
              margin-right: var(--margin-right-info);
            }
            .footer {
              position: fixed;
              bottom: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              padding: var(--padding-cell) var(--padding-table);
              background-color: #f2f2f2;
              border-top: 1px solid black;
            }
            .footer span {
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          ${
            type === "purchase order"
              ? `
                <div class="company-info">
                  <img
                    alt="School logo"
                    src="https://mzazilink.com/school_logos/${schoolPrefix}.png"
                  />
                  <div class="company-infoHeader">
                    <div style="font-size:  var(--font-size-heading);  font-weight: bold;">${schoolName}</div>
                    <div style="font-size:  var(--font-size-heading);">${schoolAddress}</div>
                    <div style="font-size:  var(--font-size-heading);">${schoolPhone}</div>
                  </div>
                <h1>LOCAL PURCHASE ORDER</h1>
                </div>
                <div>
                  <div style="font-size: var(--font-size-body);margin-bottom: var(--margin-bottom-info);">
                    To: 
                    <span style="font-weight: bold;  ">${
                      formatPrintData.company_name
                    }</span>
                  </div>
                  <div style="font-size: var(--font-size-body); margin-bottom: var(--margin-bottom-info); ">
                    Contact: 
                    <span style="font-weight: bold; font-size: var(--font-size-body); ">${
                      formatPrintData.company_address
                    }</span>
                  </div>
                  <div style="display: flex; justify-content: space-between; font-size: var(--font-size-body); margin-bottom: var(--margin-bottom-info); ">
                    <div>
                      LPO No: <span style="font-weight: bold; ">${
                        formatPrintData.requisition_number
                      }</span>
                    </div>
                    <div style=" font-size: var(--font-size-body); margin-bottom: var(--margin-bottom-info);">
                      Date: <span style="font-weight: bold; font-size: var(--font-size-body);">${date}</span>
                    </div>
                  </div>
                  <div style="font-weight: bold;font-size: var(--font-size-body)">
                    Please supply the following goods/services to ${schoolName} on or before 
                    .......................................................
                    and submit invoices without delay
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Item No</th>
                      <th>Description Of Goods</th>
                      <th>Unit</th>
                      <th>QTY</th>
                      <th>Price (KSH)</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${formatPrintData.data
                      .map(
                        (order, index) => `
                      <tr>
                        <td>${index + 1}</td>
                        <td>${order.item_name}</td>
                        <td>${order.unitOfMeasurement}</td>
                        <td>${order.quantity}</td>
                        <td>${formatDisplayNumber(order.itemPrice)}</td>
                      </tr>
                    `
                      )
                      .join("")}
                    <tr class="total-amount">
                      <td colspan="4">TOTAL AMOUNT</td>
                      <td>Ksh. ${formatDisplayNumber(
                        formatPrintData.totalPrice
                      )}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="signature-section">
                  <div class="signature-box">
                    <div style="font-weight: bold;">Prepared By: ${schoolAdmin}</div>
                    <div><span>Designation:</span> ...................</div>
                    <div><span>Date/Stamp:</span>  ....................</div>
                  </div>
                  <div class="signature-box" style="margin-top: var(--margin-top-signature);">
                    <div><span>Authorized By:</span> ......................................</div>
                    <div><span>Designation:</span> ....................</div>
                    <div><span>Date/Stamp:</span>  ....................</div>
                  </div>
                  <div class="signature-box" style="margin-top: var(--margin-top-signature);">
                    <div><span>Approved By:</span> .......................................</div>
                    <div><span>Designation:</span> ....................</div>
                    <div><span>Date/Stamp:</span>  ....................</div>
                  </div>
                </div>
                <div class="footer">
                  <div><span>ORIGINAL</span>: SUPPLIER</div>
                  <div><span>DUPLICATE</span>: ACCOUNTS</div>
                  <div><span>TRIPLICATE</span>: FILE COPY</div>
                </div>
              `
              : type === "delivery"
              ? `
                <div class="company-info">
                  <img
                    alt="School logo"
                    src="https://mzazilink.com/school_logos/${schoolPrefix}.png"
                  />
                  <div class="company-infoHeader">
                    <div>${schoolName}</div>
                    <div>${schoolAddress}</div>
                    <div>${schoolPhone}</div>
                  </div>
                </div>
                <h1>DELIVERY NOTE</h1>
                <div>
                  <div style="font-size: var(--font-size-body); margin-bottom: var(--margin-bottom-info);">
                    FROM: 
                    <span style="font-weight: bold;">${
                      formatPrintData.company_name
                    }</span>
                  </div>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      LPO No: <span style="font-weight: bold;">${
                        formatPrintData.requisition_number
                      }</span>
                    </div>
                    <div>
                      Date: <span style="font-weight: bold;">${date}</span>
                    </div>
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Item No</th>
                      <th>Description Of Goods</th>
                      <th>Unit</th>
                      <th>QTY</th>
                      <th>Price (KSH)</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${formatPrintData.data
                      .map(
                        (order, index) => `
                      <tr>
                        <td>${index + 1}</td>
                        <td>${order.item_name}</td>
                        <td>${order.unitOfMeasurement}</td>
                        <td>${order.quantity}</td>
                        <td>${formatDisplayNumber(order.itemPrice)}</td>
                      </tr>
                    `
                      )
                      .join("")}
                    <tr class="total-amount">
                      <td colspan="4">TOTAL AMOUNT</td>
                      <td>Ksh. ${formatDisplayNumber(
                        formatPrintData.totalPrice
                      )}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="signature-section">
                  <div class="signature-box">
                    <div>Deliverer: .......................................................</div>
                    <div>Designation: ..................................</div>
                  </div>
                  <div class="signature-box" style="margin-top: var(--margin-top-signature);">
                    <div>Receiver: .......................................................</div>
                    <div>Designation: ..................................</div>
                  </div>
                </div>
              `
              : type === "payment"
              ? `
                <div class="company-info">
                  <img
                    alt="School logo"
                    src="https://mzazilink.com/school_logos/${schoolPrefix}.png"
                  />
                  <div class="company-infoHeader">
                    <div>${schoolName}</div>
                    <div>${schoolAddress}</div>
                    <div>${schoolPhone}</div>
                  </div>
                </div>
                <h1>PAYMENT VOUCHER</h1>
                <div>
                  <div style="font-size: var(--font-size-body); margin-bottom: var(--margin-bottom-info);">
                    To: 
                    <span style="font-weight: bold;">${
                      formatPrintData.company_name
                    }</span>
                  </div>
                  <div style="display: flex; justify-content: space-between;">
                    <div>
                      Ref No: <span style="font-weight: bold;">${
                        formatPrintData.requisition_number
                      }</span>
                    </div>
                    <div>
                      Date: <span style="font-weight: bold;">${date}</span>
                    </div>
                  </div>
                  <div style="font-weight: bold; margin-top: var(--margin-bottom-info);">
                    Please process the payment as per the details provided below:
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Item No</th>
                      <th>Description Of Goods</th>
                      <th>Unit</th>
                      <th>QTY</th>
                      <th>Price (KSH)</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${formatPrintData.data
                      .map(
                        (order, index) => `
                      <tr>
                        <td>${index + 1}</td>
                        <td>${order.item_name}</td>
                        <td>${order.unitOfMeasurement}</td>
                        <td>${order.quantity}</td>
                        <td>${formatDisplayNumber(order.itemPrice)}</td>
                      </tr>
                    `
                      )
                      .join("")}
                    <tr class="total-amount">
                      <td colspan="4">TOTAL AMOUNT</td>
                      <td>Ksh. ${formatDisplayNumber(
                        formatPrintData.totalPrice
                      )}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="signature-section">
                  <div class="signature-box">
                    <div>Authorizing Officer: .......................................................</div>
                    <div>Designation: ..................................</div>
                  </div>
                </div>
              `
              : ""
          }
        </body>
      </html>
    `);

    printWindow.document.close(); // Close the document
    printWindow.onafterprint = () => {
      printWindow.close();
    };

    printWindow.onload = function () {
      printWindow.focus();
      printWindow.print();
    };
  } catch (error) {
    console.error("Error generating print document:", error);
    alert(
      "An error occurred while trying to generate the document for printing."
    );
    printWindow.close();
  }
};
