import "./App.css";
import { AuthProvider } from "./contexts/auth-context";
import OverViwPage from "./pages/OverViwPage";
import { createTheme } from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // Changed BrowserRouter to HashRouter
import DeliveriesPage from "./pages/deliveries/deliveries";
import { Layout as DashboardLayout } from "./layouts/dashboard/layout";
import SuppliersPage from "./pages/suppliers/suppliers";
import AllocationsPage from "./pages/allocations/allocations";
import OrderPage from "./pages/purchaseOrders/purchaseOrders";
import PaymentsPage from "./pages/Payments/payments";
import ProductsPage from "./pages/Products/products";
import ExpensesPage from "./pages/expenses/expenses";
import StudentAllocationsPage from "./pages/studentAllocations/student_allocations";
import Supplier from "./pages/suppliers/supplier/supplier";
import PurchaseOrder from "./pages/purchaseOrders/purchaseOrders/purchase-order";
import Student from "./pages/studentAllocations/student_allocation/studentAllocation";
import ServiceProvidersPage from "./pages/serviceProviders/serviceProviders";
import LoginPage from "./pages/auth/login";
import { LoginLayout } from "./layouts/auth/layout";
import { PrintDocument } from "./utils/print-documents";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
import ProductPage from "./pages/Products/product/product";
import StaffPage from "./pages/staffMembers/staff";
import StaffMember from "./pages/staffMembers/staffMember";
import LsoInventoryPage from "./pages/lsoInventory/lsoInventories";
import LsoOrderPage from "./pages/lsoOrders/LsoOrder";
import LsoOrder from "./pages/lsoOrders/lsoOrder/Lso-Order";
import ServiceProviders from "./pages/serviceProviders/serviceProvider/serviceProviders";
import SettingsPage from "./pages/settings";

function App() {
  const theme = createTheme();

  return (
    <div className="App">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route
                path="/auth/login"
                element={
                  <LoginLayout>
                    <LoginPage />
                  </LoginLayout>
                }
              />
              <Route
                path="/"
                element={
                  <DashboardLayout>
                    <OverViwPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/deliveries"
                element={
                  <DashboardLayout>
                    <DeliveriesPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/suppliers"
                element={
                  <DashboardLayout>
                    <SuppliersPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/supplier/:id/:phone_number/:full_name/:company_name"
                element={
                  <DashboardLayout>
                    <Supplier />
                  </DashboardLayout>
                }
              />
              <Route
                path="/service-providers"
                element={
                  <DashboardLayout>
                    <ServiceProvidersPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/service-provider/:id/:phone_number/:full_name/:company_name"
                element={
                  <DashboardLayout>
                    <ServiceProviders />
                  </DashboardLayout>
                }
              />
              <Route
                path="/issuance"
                element={
                  <DashboardLayout>
                    <AllocationsPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/purchase-orders"
                element={
                  <DashboardLayout>
                    <OrderPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/purchase-order/:id"
                element={
                  <DashboardLayout>
                    <PurchaseOrder />
                  </DashboardLayout>
                }
              />
              <Route
                path="/lso-orders"
                element={
                  <DashboardLayout>
                    <LsoOrderPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/lso-order/:id/:quantity/:product_name/:company_name/:unit_of_measurement/:product_id"
                element={
                  <DashboardLayout>
                    <LsoOrder />
                  </DashboardLayout>
                }
              />
              <Route
                path="/payments"
                element={
                  <DashboardLayout>
                    <PaymentsPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/inventory"
                element={
                  <DashboardLayout>
                    <ProductsPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/lso"
                element={
                  <DashboardLayout>
                    <LsoInventoryPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/inventory/:id/:tittle"
                element={
                  <DashboardLayout>
                    <ProductPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/expenses"
                element={
                  <DashboardLayout>
                    <ExpensesPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/staff"
                element={
                  <DashboardLayout>
                    <StaffPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/staff/:id"
                element={
                  <DashboardLayout>
                    <StaffMember />
                  </DashboardLayout>
                }
              />
              <Route
                path="/student-allocations"
                element={
                  <DashboardLayout>
                    <StudentAllocationsPage />
                  </DashboardLayout>
                }
              />
              <Route
                path="/student-allocation/:id"
                element={
                  <DashboardLayout>
                    <Student />
                  </DashboardLayout>
                }
              />
              <Route
                path="/settings"
                element={
                  <DashboardLayout>
                    <SettingsPage />
                  </DashboardLayout>
                }
              />

              <Route path="/print_report" element={<PrintDocument />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </AuthProvider>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        theme="dark"
      />
    </div>
  );
}

export default App;
