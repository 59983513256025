import React, { useState, useEffect } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Settings } from "../sections/settings/settings-list";
import { fetchSettingsData } from "../utils/fetchData";

const SettingsPage = () => {
  const [settingsType, setSettingsType] = useState([]);

  useEffect(() => {
    fetchSettingsData(setSettingsType);
  }, []);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Typography variant="h4">Settings</Typography>
            <Settings settingsType={settingsType} />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default SettingsPage;
