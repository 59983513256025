import {useCallback, useRef, useState} from "react";
import {DEFAULT_PER_PAGE} from "../utils/constants";


const BasePageConfigs = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    const componentRef = useRef();

    return {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    };
};

export default BasePageConfigs;
