import {useMemo} from "react";
import {useSelection} from "../../hooks/use-selection";
import * as actions from "../../layouts/dashboard/actions";
import AddStaff from "../../sections/staffs/add-staff";
import {StaffSearch} from "../../sections/staffs/staff-search";
import {StaffTable} from "../../sections/staffs/staff-table";
import {SCHOOL_STAFF_ENDPOINT} from "../../utils/constants";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import {useLoadData} from "../../services/featching-data-service";

const useStaffIds = (staff) => {
    return useMemo(() => {
        // Check if staff is not empty or undefined
        if (!staff || staff.length === 0) {
            return []; // Return an empty array
        }

        // If staff is not empty, map the ids
        return staff.map((staffMember) => staffMember.id);
    }, [staff]);
};

const StaffPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();

    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
    };

    const {
        data: staff,
        total,
        isLoading,
        setData,
        setIsLoading,
    } = useLoadData(payload, SCHOOL_STAFF_ENDPOINT);
    const staffIds = useStaffIds(staff);
    const staffSelection = useSelection(staffIds);

    return BasePage(
        "School Staff",
        <AddStaff/>,
        <StaffSearch
            data={staff}
            payload={payload}
            setData={setData}
            setIsLoading={setIsLoading}
        />,
        <StaffTable
            count={total}
            items={staff}
            onDeselectAll={staffSelection.handleDeselectAll}
            onDeselectOne={staffSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={staffSelection.handleSelectAll}
            onSelectOne={staffSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={staffSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef,
        actions.canAddStaff
    );
};

export default StaffPage;
