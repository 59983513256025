import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Box, Button, Stack, SvgIcon, Typography} from "@mui/material";
import {useSelection} from "../../../hooks/use-selection";
import {Helmet} from "react-helmet";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {printReport} from "../../../utils/print-documents";
import {ExportTableButton} from "../../../utils/export-document";
import * as actions from "../../../layouts/dashboard/actions";
import {makeNetworkCall} from "../../../utils/fetchData";
import {useParams} from "react-router-dom";
import {AllocationTable} from "../../../sections/allocations/allocations-table";
import {DEFAULT_PER_PAGE, SINGLE_STAFF_ENDPOINT, STAFF_ALLOCATIONS_ENDPOINT} from "../../../utils/constants";

export const useSingleStaff = (id) => {
    const [staffDetails, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        makeNetworkCall(
            SINGLE_STAFF_ENDPOINT,
            {
                staff_id: id,
            },
            setData,
            null,
            setIsLoading,
            {},
        );
    }, [id, setData]);

    return {staffDetails, isLoading};
};

export const useStaff = (page, rowsPerPage, id) => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetch = async () => makeNetworkCall(
            STAFF_ALLOCATIONS_ENDPOINT,
            {
                offset: page * rowsPerPage,
                per_page: rowsPerPage,
                staff_id: id,
            },
            setData,
            setTotal,
            setIsLoading,
            {},
        );
        fetch();
    }, [page, rowsPerPage, id]);

    return {data, total, isLoading};
};

const useStaffIds = (staff) => {
    return useMemo(() => {
        // Check if staff is not empty or undefined
        if (!staff || staff.length === 0) {
            return []; // Return an empty array
        }

        // If staff is not empty, map the ids
        return staff.map((staffMember) => staffMember.id);
    }, [staff]);
};

const StaffMember = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
    const {id} = useParams();
    const {
        data: staff,
        total,
        isLoading,
    } = useStaff(page, rowsPerPage, id);
    const {
        staffDetails: staffData,
        loading,
    } = useSingleStaff(id);
    const staffIds = useStaffIds(staff);
    const staffSelection = useSelection(staffIds);

    const componentRef = useRef();

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
    }, []);

    const handleRowsPerPageChange = useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    return (
        <>
            <Helmet>
                <title> Procurement | Staff</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                }}
            >
                <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between" spacing={4}>
                        <Stack spacing={1}>
                            <Typography variant="h6">
                                Items Issued to {staffData.full_name} at{" "}
                                {staffData.department} department{" "}
                            </Typography>
                            <Stack alignItems="center" direction="row" spacing={1}>
                                {actions.canPrintStaff && (
                                    <Button
                                        onClick={() =>
                                            printReport(componentRef, "Staff Issuance Report")
                                        }
                                        color="inherit"
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <LocalPrintshopIcon/>
                                            </SvgIcon>
                                        }
                                    >
                                        Print
                                    </Button>
                                )}

                                {actions.canExportStaff && (
                                    <ExportTableButton
                                        componentRef={componentRef}
                                        pageTittle="Staff"
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>

                    <AllocationTable
                        count={total.total}
                        items={staff}
                        onDeselectAll={staffSelection.handleDeselectAll}
                        onDeselectOne={staffSelection.handleDeselectOne}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        onSelectAll={staffSelection.handleSelectAll}
                        onSelectOne={staffSelection.handleSelectOne}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        selected={staffSelection.selected}
                        isLoading={isLoading || loading}
                        componentRef={componentRef}
                    />
                </Stack>
            </Box>
        </>
    );
};

export default StaffMember;
