import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SvgIcon,
  OutlinedInput,
  TextField,
  Grid,
} from "@mui/material";
import { CreateItem, fetchSettingsData } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddExpenses() {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    amount: "",
    date_of_expense: "",
    expense_type: "",
    notes: "",
    spent_by: "",
  });
  const [expenseType, setExpenseType] = React.useState([]);

  React.useEffect(() => {
    fetchSettingsData(setExpenseType);
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation checks
    if (
      !formValues.amount ||
      !formValues.date_of_expense ||
      !formValues.expense_type ||
      !formValues.spent_by
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Data to be posted
    const postData = {
      amount: formValues.amount,
      date_of_expense: formValues.date_of_expense,
      expense_type: formValues.expense_type,
      notes: formValues.notes,
      spent_by: formValues.spent_by,
    };

    // Create item
    CreateItem(postData, endpoints.NEW_EXPENSES_ENDPOINT);
  };

  return (
    <React.Fragment>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PlusIcon />
          </SvgIcon>
        }
        variant="contained"
        onClick={handleClickOpen}
      >
        Add Expenses
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader="Fill in all the inputs to be able to create an expense"
                  title="Create purchase expense"
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Expense Type",
                      placeholder: "",
                      field: "expense_type",
                    },
                    { labelName: "Amount", placeholder: "", field: "amount" },
                    {
                      labelName: "Date Of Expense",
                      placeholder: "",
                      field: "date_of_expense",
                      type: "date",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.labelName}
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        {inputField.labelName === "Notes" ? (
                          <TextField
                            multiline
                            minRows={4}
                            maxRows={6}
                            variant="outlined"
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                            style={{ width: "100%" }}
                          />
                        ) : inputField.type === "date" ? (
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              type="date"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </FormControl>
                        ) : inputField.field === "expense_type" ? (
                          <FormControl fullWidth>
                            <InputLabel>{inputField.labelName}</InputLabel>
                            <Select
                              value={formValues.expense_type}
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                              label={inputField.labelName}
                            >
                              {expenseType?.expenditure_type
                                ?.split(",")
                                ?.map((type) => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <FormControl sx={{ width: "100%" }}>
                            <OutlinedInput
                              defaultValue=""
                              fullWidth
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </FormControl>
                        )}
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Spent By",
                      placeholder: "",
                      field: "spent_by",
                    },
                    // {
                    //   labelName: "Narration (optional) ",
                    //   placeholder: "",
                    //   field: "notes",
                    // },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        {inputField.labelName === "Narration (optional) " ? (
                          <p>{inputField.labelName}</p>
                        ) : (
                          <p>
                            {inputField.labelName}
                            <span style={{ color: "red" }}> *</span>
                          </p>
                        )}
                        {inputField.labelName === "Narration (optional) " ? (
                          <TextField
                            multiline
                            minRows={4}
                            maxRows={6}
                            variant="outlined"
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <FormControl sx={{ width: "100%" }}>
                            <OutlinedInput
                              defaultValue=""
                              fullWidth
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          </FormControl>
                        )}
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
