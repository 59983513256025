import { useCallback, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Collapse,
  CircularProgress,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { fetchSettings, makeNetworkCall } from "../../utils/fetchData";
import BasePageConfigs from "../../pages/page-configs";
import {
  NEW_SETTINGS_ENDPOINT,
  UPDATE_SETTINGS_ENDPOINT,
} from "../../utils/constants";

export const Settings = () => {
  const [settingsType, setSettingsType] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSettings(setSettingsType);
      } catch (error) {
        toast.error("Error fetching settings");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [settings, setSettings] = useState({
    id: 1,
    units_of_measurements: "",
    expenditure_type: "",
    item_categories: "",
    send_threshold_alert: false,
    access_levels: "",
    staff_roles: "",
    departments: "",
  });

  useEffect(() => {
    setSettings(settingsType);
  }, [settingsType]);

  const parseSettingsType = (data) => {
    const entities = [
      {
        category: "Unit of Measurements",
        items:
          data?.units_of_measurements
            ?.split(",")
            ?.map((name) => ({ name, status: false })) || [],
      },
      {
        category: "Departments",
        items:
          data?.departments
            ?.split(",")
            ?.map((name) => ({ name, status: false })) || [],
      },
      {
        category: "Item Categories",
        items:
          data?.item_categories
            ?.split(",")
            ?.map((name) => ({ name, status: false })) || [],
      },
    ];

    return entities;
  };

  const { page, rowsPerPage } = BasePageConfigs();
  const [entities, setEntities] = useState(parseSettingsType(settings));
  const [modifiedEntities, setModifiedEntities] = useState(
    parseSettingsType(settings)
  );
  const [isEditing, setIsEditing] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [viewAllOpen, setViewAllOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addEntryDialogOpen, setAddEntryDialogOpen] = useState(false);
  const [newEntryName, setNewEntryName] = useState("");
  const [currentAddEntryCategory, setCurrentAddEntryCategory] = useState(null);

  useEffect(() => {
    setEntities(parseSettingsType(settings));
    setModifiedEntities(parseSettingsType(settings));
  }, [settings]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleActionClick = useCallback((actionType, entity) => {
    setDialogType(actionType);
    setSelectedEntity(entity);
    if (actionType === "Edit") {
      setEditedName(entity.name);
    }
    setDialogOpen(true);
  }, []);

  const formatSettings = (entities) => {
    const formattedSettings = {
      "Unit of Measurements": entities
        .find((cat) => cat.category === "Unit of Measurements")
        .items.map((item) => item.name)
        .join(", "),
      Departments: entities
        .find((cat) => cat.category === "Departments")
        .items.map((item) => item.name)
        .join(", "),
      "Item Categories": entities
        .find((cat) => cat.category === "Item Categories")
        .items.map((item) => item.name)
        .join(", "),
    };
    return formattedSettings;
  };

  const handleCancel = useCallback(() => {
    setDialogOpen(false);
    setDialogType("");
    setSelectedEntity(null);
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleViewAll = useCallback((category) => {
    setCurrentCategory(category);
    setViewAllOpen(true);
  }, []);

  const handleViewAllClose = useCallback(() => {
    setViewAllOpen(false);
    setCurrentCategory(null);
  }, []);

  const handleSelectItem = useCallback(
    (item) => {
      setModifiedEntities((prevEntities) =>
        prevEntities.map((cat) =>
          cat.category === currentCategory
            ? {
                ...cat,
                items: cat.items.map((i) =>
                  i.name === item.name
                    ? { ...i, status: true }
                    : { ...i, status: false }
                ),
              }
            : cat
        )
      );
      setSelectedItem(item);
    },
    [currentCategory]
  );

  const handleAddEntry = useCallback((category) => {
    setCurrentAddEntryCategory(category);
    setAddEntryDialogOpen(true);
  }, []);

  const handleAddEntryConfirm = useCallback(async () => {
    let itemCategory;
    if (currentAddEntryCategory) {
      itemCategory = modifiedEntities.find(
        (cat) => cat.category === currentAddEntryCategory
      );
    } else {
      itemCategory = modifiedEntities.find((cat) =>
        cat.items.some((item) => item.name === selectedEntity.name)
      );
    }

    if (!itemCategory) {
      console.error("Category not found for the selected entity");
      return;
    }

    // Trim the new entry name
    const trimmedEntryName = newEntryName.trim();

    // Check if the new entry already exists
    const entryExists = itemCategory.items.some(
      (item) => item.name === trimmedEntryName
    );
    if (dialogType === "Add" && entryExists) {
      toast.error("Error: This item already exists.");
      return;
    }

    let updatedEntities;

    if (dialogType === "Delete") {
      updatedEntities = modifiedEntities.map((cat) =>
        cat.category === itemCategory.category
          ? {
              ...cat,
              items: cat.items.filter(
                (item) => item.name !== selectedEntity.name
              ),
            }
          : cat
      );
    } else if (dialogType === "Edit") {
      updatedEntities = modifiedEntities.map((cat) =>
        cat.category === itemCategory.category
          ? {
              ...cat,
              items: cat.items.map((item) =>
                item.name === selectedEntity.name
                  ? { ...item, name: editedName.trim() }
                  : item
              ),
            }
          : cat
      );
    } else {
      updatedEntities = modifiedEntities.map((cat) =>
        cat.category === itemCategory.category
          ? {
              ...cat,
              items: [...cat.items, { name: trimmedEntryName, status: false }],
            }
          : cat
      );
    }

    setModifiedEntities(updatedEntities);

    const updatedSettings = formatSettings(updatedEntities);
    try {
      await makeNetworkCall(
        UPDATE_SETTINGS_ENDPOINT,
        {
          units_of_measurements: updatedSettings["Unit of Measurements"],
          departments: updatedSettings.Departments,
          item_categories: updatedSettings["Item Categories"],
        },
        setEntities,
        null,
        setLoading,
        null
      );
      setAddEntryDialogOpen(false);
      setDialogOpen(false);
      setNewEntryName("");
      setCurrentAddEntryCategory(null);
      window.location.reload();
      toast.success("Settings updated successfully!");
    } catch (error) {
      toast.error("Error updating settings:", error);
    }
  }, [
    newEntryName,
    currentCategory,
    modifiedEntities,
    settings,
    dialogType,
    editedName,
    selectedEntity,
    page,
    rowsPerPage,
  ]);

  const handleAddEntryCancel = useCallback(() => {
    setAddEntryDialogOpen(false);
    setNewEntryName("");
    setCurrentAddEntryCategory(null);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {entities.map((category) => (
        <Card key={category.category} sx={{ mb: 3 }}>
          <CardHeader
            subheader={`Manage ${category.category}`}
            title={category.category}
          />
          {!isEditing && (
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button variant="contained" onClick={handleEdit}>
                Edit
              </Button>
            </CardActions>
          )}
          <Divider />
          <CardContent>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2} wrap="wrap">
                {category.items.slice(0, 6).map((item) => (
                  <Grid key={item.name} xs={12} sm={6} md={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedItem === item}
                            onChange={() => handleSelectItem(item)}
                          />
                        }
                        label={item.name}
                        sx={{
                          minWidth: 150,
                          backgroundColor:
                            selectedItem === item
                              ? "rgba(0, 0, 0, 0.1)"
                              : "transparent",
                          borderRadius: "4px",
                          padding: "4px",
                        }}
                      />
                      {isEditing && selectedItem === item && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                          <div>
                            <IconButton
                              color="primary"
                              onClick={() => handleActionClick("Edit", item)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleActionClick("Delete", item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Collapse>
                      )}
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            )}
          </CardContent>
          {isEditing && (
            <>
              <Divider />
              <CardActions sx={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  onClick={() => handleAddEntry(category.category)}
                >
                  {" "}
                  Add an entry
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleViewAll(category.category)}
                >
                  View All
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      ))}

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {dialogType === "Delete" ? (
            <Typography>
              Are you sure you want to delete{" "}
              {selectedEntity && selectedEntity.name}?
            </Typography>
          ) : (
            <TextField
              label="Edit Name"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleAddEntryConfirm} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* View All Dialog */}
      <Dialog
        open={viewAllOpen}
        onClose={handleViewAllClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>All {currentCategory}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} wrap="wrap">
            {currentCategory &&
              entities
                .find((cat) => cat.category === currentCategory)
                .items.map((item) => (
                  <Grid key={item.name} xs={12} sm={6} md={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.name === selectedItem?.name}
                            onChange={() => handleSelectItem(item)}
                          />
                        }
                        label={item.name}
                        sx={{
                          minWidth: 150,
                          backgroundColor:
                            item.name === selectedItem?.name
                              ? "rgba(0, 0, 0, 0.1)"
                              : "transparent",
                          borderRadius: "4px",
                          padding: "4px",
                        }}
                      />
                      {isEditing && item.name === selectedItem?.name && (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                          <div>
                            <IconButton
                              color="primary"
                              onClick={() => handleActionClick("Edit", item)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleActionClick("Delete", item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Collapse>
                      )}
                    </Stack>
                  </Grid>
                ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleViewAllClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Entry Dialog */}
      <Dialog open={addEntryDialogOpen} onClose={handleAddEntryCancel}>
        <DialogTitle>Add New Entry</DialogTitle>
        <DialogContent>
          <TextField
            label="Entry Name"
            value={newEntryName}
            onChange={(e) => setNewEntryName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddEntryCancel}>Cancel</Button>
          <Button onClick={handleAddEntryConfirm} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
