import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import { useNavigate } from "react-router-dom";
import { DateTimeFormateTimeStamp } from "../../utils/dateTime-fomater";
import AddStaff from "./add-staff";

export const StaffTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    isLoading = false,
    componentRef,
  } = props;

  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState({
    open: false,
    staffData: null,
  });

  const handleNavigate = (id) => {
    navigate(`/staff/${id}`);
  };

  const handleEditClick = (event, staff) => {
    event.stopPropagation();
    setOpenEdit({ open: true, staffData: staff });
  };

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 400 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Contact No.</TableCell>
                <TableCell>ID/TSC No.</TableCell>
                <TableCell>Staff Role</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Date Added</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <ProgressBars />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      No data available
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                items.map((staff, index) => (
                  <TableRow
                    onClick={() => handleNavigate(staff.id)}
                    hover
                    key={staff.id}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{staff.full_name}</TableCell>
                    <TableCell>{staff.contact_number}</TableCell>
                    <TableCell>{staff.id_number}</TableCell>
                    <TableCell>{staff.user_role}</TableCell>
                    <TableCell>{staff.department}</TableCell>
                    <TableCell>
                      {DateTimeFormateTimeStamp(staff.timestamp_registered)}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(event) => handleEditClick(event, staff)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />

      {openEdit.open && (
        <div style={{ display: "none" }}>
          <AddStaff mode="edit" data={openEdit.staffData} />
        </div>
      )}
    </Card>
  );
};
