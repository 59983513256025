const storedToken =
  typeof window !== "undefined" ? localStorage.getItem("userData") : null;
const user = storedToken ? JSON.parse(storedToken) : null;

const userRole = user?.user?.user_role;
const overviewActions = {
  1: "Add Item",
  2: "Search",
  3: "Print",
  4: "Export",
  6: "Access the Page",
};
const suppliersActions = {
  1: "Add Supplier",
  2: "Search Supplier",
  3: "Print Supplier",
  4: "Export Supplier",
  5: "Access Single Supplier",
  6: "Access Supplier Page",
};
const ordersActions = {
  1: "Add Order",
  2: "Search Order",
  3: "Print Order",
  4: "Export Order",
  5: "Access Single Order",
  6: "Access Order Page",
};
const deliveriesActions = {
  1: "Add Delivery",
  2: "Search Delivery",
  3: "Print Delivery",
  4: "Export Delivery",
  6: "Access Delivery Page",
};
const paymentsActions = {
  1: "Make Payment",
  2: "Search Payment",
  3: "Print Payment",
  4: "Export Payment",
  5: "Access Payment Page",
};
const inventoryActions = {
  1: "Add Inventory",
  2: "Search Inventory",
  3: "Print Inventory",
  4: "Export Inventory",
  5: "Access Inventory Page",
};
const issuanceActions = {
  1: "Add Issuance",
  2: "Search Issuance",
  3: "Print Issuance",
  4: "Export Issuance",
  5: "Access Issuance Page",
};
const expensesActions = {
  1: "Add Expense",
  2: "Search Expense",
  3: "Print Expense",
  4: "Export Expense",
  5: "Access Expense Page",
};
const studentActions = {
  1: "Add Student",
  2: "Search Student",
  3: "Print Student",
  4: "Export Student",
  5: "Access Single Student",
  6: "Access Student Page",
};

const staffActions = {
  1: "Add staff",
  2: "Search staff",
  3: "Print staff",
  4: "Export staff",
  5: "Access Single staff page",
  6: "Access staff Page",
};

export const checkAccess = (role, action) => {
  switch (role) {
    case "ADMIN":
      return true; // Allow ADMIN to access everything
    case "SUPER_ADMIN":
      return true; // Allow SUPER_ADMIN to access everything
    case "SUBORDINATE":
      // Restrict SUBORDINATE from accessing suppliersActions and paymentsActions
      if (
        action.startsWith("Add Supplier") ||
        action.startsWith("Add Expense") ||
        action.startsWith("Print Expense") ||
        action.startsWith("Search Expense") ||
        action.startsWith("Add Order") ||
        action.startsWith("Print Supplier") ||
        action.startsWith("Export Supplier") ||
        action.startsWith("Search Supplier") ||
        action.startsWith("Make Payment") ||
        action.startsWith("Print Payment") ||
        action.startsWith("Export Payment") ||
        action.startsWith("Search Payment")
      ) {
        return false;
      } else {
        return true;
      }
    default:
      return false;
  }
};

export const canAddSupplier = checkAccess(userRole, "Add Supplier");
export const canAddExpenses = checkAccess(userRole, "Add Expense");
export const canAddStaff = checkAccess(userRole, "Add staff");
export const canAddOrder = checkAccess(userRole, "Add Order");
export const canAddPayment = checkAccess(userRole, "Add Payment");
export const canAddInventory = checkAccess(userRole, "Add Inventory");
export const canAddDelivery = checkAccess(userRole, "Add Delivery");
export const canAddIssuance = checkAccess(userRole, "Add Issuance");
export const canAddStudent = checkAccess(userRole, "Add Student");
export const canPrintSupplier = checkAccess(userRole, "Print Supplier");
export const canPrintExpenses = checkAccess(userRole, "Print Expense");
export const canPrintStaff = checkAccess(userRole, "Print staff");
export const canPrintOrder = checkAccess(userRole, "Print Order");
export const canPrintPayment = checkAccess(userRole, "Print Payment");
export const canPrintInventory = checkAccess(userRole, "Print Inventory");
export const canPrintDelivery = checkAccess(userRole, "Print Delivery");
export const canPrintIssuance = checkAccess(userRole, "Print Issuance");
export const canPrintStudent = checkAccess(userRole, "Print Student");
export const canExportSupplier = checkAccess(userRole, "Export Supplier");
export const canExportExpenses = checkAccess(userRole, "Export Expense");
export const canExportStaff = checkAccess(userRole, "Export staff");
export const canExportOrder = checkAccess(userRole, "Export Order");
export const canExportPayment = checkAccess(userRole, "Export Payment");
export const canExportInventory = checkAccess(userRole, "Export Inventory");
export const canExportDelivery = checkAccess(userRole, "Export Delivery");
export const canExportIssuance = checkAccess(userRole, "Export Issuance");
export const canExportStudent = checkAccess(userRole, "Export Student");
export const canSearchSupplier = checkAccess(userRole, "Search Supplier");
export const canSearchExpenses = checkAccess(userRole, "Search Expense");
export const canSearchStaff = checkAccess(userRole, "Search staff");
export const canSearchOrder = checkAccess(userRole, "Search Order");
export const canSearchPayment = checkAccess(userRole, "Search Payment");
export const canSearchInventory = checkAccess(userRole, "Search Inventory");
export const canSearchDelivery = checkAccess(userRole, "Search Delivery");
export const canSearchIssuance = checkAccess(userRole, "Search Issuance");
export const canSearchStudent = checkAccess(userRole, "Search Student");
