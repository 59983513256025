import { Helmet } from "react-helmet";
import { Box, Button, Stack, SvgIcon, Typography } from "@mui/material";
import { printReport } from "../utils/print-documents";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { ExportTableButton } from "../utils/export-document";
import { PAGE_STACKS_SPACING, PAGE_TITLE_VARIANT } from "./configs";

const BasePage = (
  pageTitle,
  addItemButton,
  searchBar,
  tableContent,
  componentRef,
  canPerformAction = true
) => {
  return (
    <>
      <Helmet>
        <title>{`Procurement | ` + pageTitle}</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
        }}
      >
        <Stack spacing={PAGE_STACKS_SPACING}>
          <Stack direction="row" justifyContent="space-between" spacing={0}>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Typography variant={PAGE_TITLE_VARIANT}>{pageTitle}</Typography>
              <Stack alignItems="center" direction="row" spacing={0}>
                <Button
                  onClick={() =>
                    printReport(componentRef, `${pageTitle.toUpperCase()}`)
                  }
                  color="secondary"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <LocalPrintshopIcon />
                    </SvgIcon>
                  }
                >
                  Print
                </Button>
                <ExportTableButton
                  componentRef={componentRef}
                  pageTittle={pageTitle.replace(" ", "_").toLowerCase()}
                />
              </Stack>
            </Stack>
            {canPerformAction && addItemButton != null && (
              <div>{addItemButton}</div>
            )}
          </Stack>
          {searchBar != null && searchBar}
          {tableContent}
        </Stack>
      </Box>
    </>
  );
};

export default BasePage;
