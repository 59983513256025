// PaymentTable component
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Grid,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import { SeverityPill } from "../../components/severity-pill";
import { formatDisplayNumber } from "../../utils/number-formter";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { DateTimeFormateTimeStamp } from "../../utils/dateTime-fomater";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import ImageViewer from "../../components/image-popup";
import AddPayment from "./add-payments";

export const PaymentTable = (props) => {
  const {
    totals = {},
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;
  const navigate = useNavigate();

  const handleNavigate = (payment) => {
    const { id } = payment;
    navigate(`/purchase-order/${id}`);
  };
  const handleEditClick = (event, payment) => {
    event.stopPropagation();
    setOpenEdit({ open: true, data: payment });
  };

  const [openEdit, setOpenEdit] = React.useState({
    open: false,
    data: null,
  });

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Cheque Number</TableCell>
                <TableCell>Mode Of Payment</TableCell>
                <TableCell>Amount Paid</TableCell>
                <TableCell>LPO No</TableCell>
                <TableCell>Invoiced Amount</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Processed By</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Date Paid</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <CustomNoRowsOverlay />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {items.map((payment, index) => {
                  const isSelected = selected.includes(payment.payment.id);

                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow
                      hover
                      key={payment.payment.id}
                      selected={isSelected}
                    >
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {rowNum}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {payment.payment.cheque_number || "N/A"}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {payment.payment.mode_of_payment}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        <SeverityPill color="success">
                          {` ksh. ${formatDisplayNumber(
                            payment.payment.amount
                          )}`}
                        </SeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {payment.payment.purchase_order.order_number}
                      </TableCell>

                      <TableCell sx={{ padding: "0px" }}>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <SeverityPill
                                onClick={() =>
                                  handleNavigate(payment.payment.purchase_order)
                                }
                                color="warning"
                              >
                                {` ksh. ${formatDisplayNumber(
                                  payment.invoiced_amount
                                )}`}
                              </SeverityPill>
                            </React.Fragment>
                          }
                          primaryTypographyProps={{
                            variant: "subtitle1",
                          }}
                          secondary={
                            payment.payment.payment_image && (
                              <ImageViewer
                                imageUrl={payment.payment.payment_image}
                                tittle={"Invoice Payment image"}
                              />
                            )
                          }
                          secondaryTypographyProps={{
                            variant: "caption",
                          }}
                        />
                      </TableCell>

                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        <SeverityPill color="error">
                          {` ksh. ${formatDisplayNumber(payment.balance)}`}
                        </SeverityPill>
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {payment.payment.processed_by.full_name}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                        sx={{ padding: "0px" }}
                      >
                        <ListItemText
                          primary={`${payment.payment.purchase_order.supplier.full_name}- ${payment.payment.purchase_order.supplier.phone_number}`}
                          primaryTypographyProps={{
                            variant: "subtitle1",
                          }}
                          secondary={
                            payment.payment.purchase_order.supplier.company_name
                          }
                          secondaryTypographyProps={{
                            variant: "caption",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(payment.payment.purchase_order)
                        }
                      >
                        {DateTimeFormateTimeStamp(
                          payment.payment.timestamp_paid
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => handleEditClick(event, payment)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginLeft: 16 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            Total Amount Paid:{" "}
            <SeverityPill color="success">
              {` ksh. ${formatDisplayNumber(totals.total_amount_paid)}`}
            </SeverityPill>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total Order Amount:{" "}
            <SeverityPill color="warning">
              {` ksh. ${formatDisplayNumber(totals.total_invoiced_amount)}`}
            </SeverityPill>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Total Balance:{" "}
            <SeverityPill color="error">
              {` ksh. ${formatDisplayNumber(totals.total_balance)}`}
            </SeverityPill>
          </Typography>
        </Grid>

        <TablePagination
          component="div"
          count={totals.total}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Grid>
      {openEdit.open && (
        <div style={{ display: "none" }}>
          <AddPayment mode="edit" data={openEdit.data} />
        </div>
      )}
    </Card>
  );
};

PaymentTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
