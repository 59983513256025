import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {ALLOCATIONS_ENDPOINT,} from "../../utils/constants";
import BasicDatePicker from "./date-picker";
import {BaseSearchBox} from "../search-box";
import {AllocationSortFilters} from "../../utils/constants-types";

export const AllocationSearch = (
    {
        data,
        setData,
        setLoading,
        setTotals,
        payload,
    }
) => {
    const [date, setDate] = useState("");
    useEffect(() => {
        if (date) {
            payload.date = date;
        }
    }, [date]);

    const dateFilters = (
        <Box sx={{marginLeft: "25px"}}>
            <BasicDatePicker date={date} setDate={setDate} label=""/>
        </Box>
    );

    return BaseSearchBox({
        placeholder: "Enter department, inventory name...",
        endpoint: ALLOCATIONS_ENDPOINT,
        data: data,
        setData: setData,
        setIsLoading: setLoading,
        setTotals: setTotals,
        payload: payload,
        otherFilters: dateFilters,
        sortFilters: AllocationSortFilters,
    });
};
