import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Stack, SvgIcon, Typography } from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import AddExpenses from "../../sections/expenses/add-expenses";
import { ExpensesSearch } from "../../sections/expenses/expenses-search";
import { ExpensesTable } from "../../sections/expenses/expenses-table";
import axios from "axios";
import { Helmet } from "react-helmet";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { printReport } from "../../utils/print-documents";
import { ExportTableButton } from "../../utils/export-document";
import * as actions from "../../layouts/dashboard/actions";
import {DEFAULT_PER_PAGE, EXPENSES_ENDPOINT} from "../../utils/constants";

export const useExpenses = (page, rowsPerPage) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const storedToken = localStorage.getItem("token").toString();
        const params = new FormData();
        params.append("offset", page * rowsPerPage);
        params.append("per_page", rowsPerPage);
        const response = await axios.post(
          EXPENSES_ENDPOINT,

          params,
          {
            headers: {
              Token: storedToken,
            },
          }
        );

        setData(response.data.data);
        setTotal(response.data.message);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, rowsPerPage, setData]);

  return { data, total, isLoading, setIsLoading, setData };
};

const useExpenseIds = (expenses) => {
  return useMemo(() => {
    // Check if expenses is not empty or undefined
    if (!expenses || expenses.length === 0) {
      return []; // Return an empty array
    }

    // If expenses is not empty, map the ids
    return expenses.map((expense) => expense.id);
  }, [expenses]);
};

const ExpensesPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const {
    data: expenses,
    total,
    isLoading,
    setData,
    setIsLoading,
  } = useExpenses(page, rowsPerPage);
  const expenseIds = useExpenseIds(expenses);
  const expensesSelection = useSelection(expenseIds);

  const componentRef = useRef();

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  return (
    <>
      <Helmet>
        <title> Procurement | Expenses</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Expenses</Typography>
              <Stack alignItems="center" direction="row" spacing={1}>
                {actions.canPrintExpenses && (
                  <Button
                    onClick={() =>
                      printReport(componentRef, "List of Expenses")
                    }
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <LocalPrintshopIcon />
                      </SvgIcon>
                    }
                  >
                    Print
                  </Button>
                )}

                {actions.canExportExpenses && (
                  <ExportTableButton
                    componentRef={componentRef}
                    pageTittle="expenses"
                  />
                )}
              </Stack>
            </Stack>
            <div>{actions.canAddExpenses && <AddExpenses />}</div>
          </Stack>
          {actions.canSearchExpenses && (
            <ExpensesSearch
              page={page}
              rowsPerPage={rowsPerPage}
              setData={setData}
              setIsLoading={setIsLoading}
            />
          )}
          <ExpensesTable
            count={total}
            items={expenses}
            onDeselectAll={expensesSelection.handleDeselectAll}
            onDeselectOne={expensesSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={expensesSelection.handleSelectAll}
            onSelectOne={expensesSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={expensesSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
          />
        </Stack>
      </Box>
    </>
  );
};

export default ExpensesPage;
