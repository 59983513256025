import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  ISSUE_STUDENT_ITEM_ENDPOINT,
  RETURN_STUDENT_ITEM_ENDPOINT,
} from "../../utils/constants";
import { CreateItem } from "../../utils/fetchData";
import ProductSelection from "../products/product-configs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddStudentAllocations({
  rowsPerPage,
  page,
  student_number,
}) {
  const [open, setOpen] = useState(false);
  const [unitOfMeasurement, setProduct] = useState("");
  const [formValues, setFormValues] = useState({
    product_id: "",
    quantity: "",
    selectedStudentNumber: "",
  });
  const [isIssuing, setIsIssuing] = useState(false);

  const handleClickOpen = (isIssue) => {
    setOpen(true);
    setIsIssuing(isIssue);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleStudentAllocationSubmit = async () => {
    const issuableData = {
      student_number: student_number,
      product_id: formValues.product_id,
      quantity: -formValues.quantity, // Make quantity negative
    };
    const receivableData = {
      student_number: student_number,
      product_id: formValues.product_id,
      quantity: formValues.quantity,
    };

    let postData;
    if (isIssuing) {
      postData = issuableData;
    } else {
      postData = receivableData;
    }

    // Check if any required field is empty
    if (
      !postData.student_number ||
      postData.product_id === "" ||
      !postData.quantity
    ) {
      // Toast an error message
      toast.error("Please fill in all required fields.");
      return;
    }

    let apiUrl;
    if (isIssuing) {
      apiUrl = ISSUE_STUDENT_ITEM_ENDPOINT;
    } else {
      apiUrl = RETURN_STUDENT_ITEM_ENDPOINT;
    }
    await CreateItem(postData, apiUrl);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{ marginRight: "10px" }}
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={() => handleClickOpen(true)}
        >
          Issue an Item
        </Button>
        <Button
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={() => handleClickOpen(false)}
        >
          Receive an Item
        </Button>
      </Box>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={
                    isIssuing
                      ? "Fill in all the inputs to issue a product to a given student"
                      : "Fill in all the inputs to receive a product from a given student"
                  }
                  title={isIssuing ? "Issue an Item" : "Receive an Item"}
                />

                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItem>
                    <ProductSelection
                      setFormValues={setFormValues}
                      setProduct={setProduct}
                      productType="Inventories"
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Quantity" + unitOfMeasurement,
                      placeholder: "",
                      field: "quantity",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          <span style={{ color: "red" }}>* </span>
                          {inputField.labelName}
                        </p>
                        <FormControl sx={{ width: "100%" }}>
                          {inputField.field === "student_number" ? (
                            <TextField
                              defaultValue=""
                              fullWidth
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <OutlinedInput
                              defaultValue=""
                              fullWidth
                              onChange={(e) =>
                                handleInputChange(
                                  inputField.field,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </FormControl>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  onClick={handleStudentAllocationSubmit}
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
