// Page component
import {useEffect, useMemo, useState} from "react";
import {useSelection} from "../../hooks/use-selection";
import {AllocationTable} from "../../sections/allocations/allocations-table";
import {AllocationSearch} from "../../sections/allocations/allocations-search";
import AddAllocation from "../../sections/allocations/add-allocations";
import * as actions from "../../layouts/dashboard/actions";
import ReceiveAllocation from "../../sections/allocations/receive-allocations";
import {ALLOCATIONS_ENDPOINT} from "../../utils/constants";
import {makeNetworkCall} from "../../utils/fetchData";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import {useLoadData} from "../../services/featching-data-service";

export const useAllocations = (page, rowsPerPage) => {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () =>
            makeNetworkCall(
                ALLOCATIONS_ENDPOINT,
                {offset: page * rowsPerPage, per_page: rowsPerPage},
                setData,
                setTotals,
                setIsLoading,
                {},
                "POST"
            );
        fetchData();
    }, [page, rowsPerPage]);

    return {data, totals, isLoading, setTotals, setData, setIsLoading};
};

const useAllocationIds = (allocations) => {
    return useMemo(() => {
        if (!allocations) {
            return [];
        }
        return allocations.map((allocation) => allocation.id);
    }, [allocations]);
};

const AllocationsPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();

    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
    };

    const {
        data: allocations,
        totals,
        isLoading,
        setTotals,
        setData,
        setIsLoading,
    } = useLoadData(payload, ALLOCATIONS_ENDPOINT);
    const allocationIds = useAllocationIds(allocations);
    const allocationsSelection = useSelection(allocationIds);

    return BasePage(
        "Staff Item Issuance",
        <>
            <AddAllocation page={page} rowsPerPage={rowsPerPage}/>
            <ReceiveAllocation page={page} rowsPerPage={rowsPerPage}/>
        </>,
        <AllocationSearch
            isLoading={isLoading}
            setTotals={setTotals}
            setLoading={setIsLoading}
            setData={setData}
            data={allocations}
            payload={payload}
        />,
        <AllocationTable
            count={totals.total}
            items={allocations}
            onDeselectAll={allocationsSelection.handleDeselectAll}
            onDeselectOne={allocationsSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={allocationsSelection.handleSelectAll}
            onSelectOne={allocationsSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={allocationsSelection.selected}
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef,
        actions.canAddIssuance
    );
};

export default AllocationsPage;
