import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeNetworkCall } from "../../../utils/fetchData";
import BasicDatePicker from "../../allocations/date-picker";
import { PRODUCTS_ENDPOINT, STREAMS_ENDPOINT } from "../../../utils/constants";
import { useFetchData } from "../../../services/featching-data-service";

export const AllocationItemsSearch = ({
  inputData,
  setInputData,
  setData,
  getStudent,
  student,
  setStudent,
  setIsLoading,
  rowsPerPage,
  page,
  date,
  setDate,
}) => {
  const [streams, setStreams] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [selectedProductIdProduct, setSelectedProductIdProduct] = useState("");
  const [newDate, setNewDate] = useState("");

  const dateValue = newDate.toString();

  newDate && setDate(dateValue);

  const [error, setError] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const { data: products } = useFetchData(
    page,
    rowsPerPage,
    PRODUCTS_ENDPOINT,
    null,
    null
  );

  useEffect(() => {
    if (products) {
      const filteredProducts = products.filter(
        (product) =>
          product.issuable_to_student || product.receivable_from_student
      );
      setProductsData(filteredProducts);
      // setInputData((prevValues) => ({
      //   ...prevValues,
      //   productID: filteredProducts[0]?.product?.id,
      // }));
    }
  }, [products]);

  useEffect(() => {
    setDisabledBtn(student.length === 0);
  }, [student]);

  const fetchStreamsData = async () =>
    makeNetworkCall(
      STREAMS_ENDPOINT,
      { form: inputData.forms },
      setStreams,
      null,
      setIsLoading,
      {},
      "POST",
      setInputData,
      inputData
    );

  useEffect(() => {
    fetchStreamsData();
  }, [inputData.forms]);
  useEffect(() => {
    fetchStreamsData();
  }, []);
  const handleInputChange = (prop) => (event) => {
    setInputData({ ...inputData, [prop]: event.target.value });
  };

  const handleProductSelection = (value) => {
    setSelectedProductIdProduct(value.id);
    setInputData((prevValues) => ({
      ...prevValues,
      productID: value.product.id,
    }));
  };

  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="form-label">Form</InputLabel>
            <Select
              labelId="form-label"
              id="form-select"
              label="Form"
              value={inputData.forms}
              onChange={handleInputChange("forms")}
            >
              <MenuItem value={1}>Form 1</MenuItem>
              <MenuItem value={2}>Form 2</MenuItem>
              <MenuItem value={3}>Form 3</MenuItem>
              <MenuItem value={4}>Form 4</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="stream-label">Stream</InputLabel>
            <Select
              labelId="stream-label"
              id="stream-select"
              value={inputData.selectedStream}
              label="Stream"
              onChange={handleInputChange("selectedStream")}
            >
              {streams.map((stream) => (
                <MenuItem key={stream.id} value={stream}>
                  {stream}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid sx={{ display: "flex" }} item xs={12} sm={12} md={3} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="form-label">Item</InputLabel>
            <Select
              onChange={handleInputChange("itemType")}
              value={inputData.itemType}
              labelId="form-label"
              id="form-select"
              label="Form"
            >
              <MenuItem value={"received"}>Received Items</MenuItem>
              <MenuItem value={"issued"}>Issued Items</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControl fullWidth>
            <Autocomplete
              options={productsData}
              getOptionLabel={(option) =>
                option.product.item_name.toUpperCase()
              }
              value={productsData.find(
                (data) => data.product.id === selectedProductIdProduct
              )}
              onChange={(event, value) => handleProductSelection(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Products"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Box sx={{ marginLeft: "25px" }}>
            <BasicDatePicker date={newDate} setDate={setNewDate} label="" />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
