import PropTypes from "prop-types";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { MyChart } from "../../components/chart";

const useChartOptions = (labels) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
    },
    colors: [theme.palette.success.main, theme.palette.error.main],
    dataLabels: {
      enabled: false,
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

export const OverviewTraffic = (props) => {
  const { chartSeries, labels, sx, total } = props;
  const chartOptions = useChartOptions(labels);
  const theme = useTheme();

  const iconMap = {
    "Not Delivered": (
      <SvgIcon sx={{ color: theme.palette.error.main }}>
        <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
      </SvgIcon>
    ),
    "Delivered": (
      <SvgIcon sx={{ color: theme.palette.success.main }}>
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
      </SvgIcon>
    ),
  };

  return (
    <Card sx={sx}>
      <CardHeader title="Order deliveries" />
      <CardContent>
        <MyChart
          height={300}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          {chartSeries.map((item, index) => {
            const percentage = ((item / total) * 100).toFixed(2); // Calculate the percentage
            const label = labels[index];

            return (
              <Box
                key={label}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {iconMap[label]}
                <Typography sx={{ my: 1 }} variant="h6">
                  {label}
                </Typography>
                <Typography color="text.secondary" variant="subtitle2">
                  {percentage}%
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

OverviewTraffic.propTypes = {
  chartSeries: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
