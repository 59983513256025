import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ExportTableButton } from "../../../utils/export-document.js";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { printReport } from "../../../utils/print-documents.js";
import { makeNetworkCall } from "../../../utils/fetchData.js";
import { PurchaseOrdersTable } from "../../../sections/purchaseOrders/purchaseOrders-table.js";
import { DeliveriesTable } from "../../../sections/deliveries/deliveries-table.js";
import { PaymentTable } from "../../../sections/payments/payments-table.js";
import {
  DEFAULT_PER_PAGE,
  SUPPLIER_DELIVERIES_ENDPOINT,
  SUPPLIER_ORDERS_ENDPOINT,
  SUPPLIER_PAYMENTS_ENDPOINT,
} from "../../../utils/constants.js";
import AddPurchaseOrders from "../../../sections/purchaseOrders/add-purchaseOrders.js";

const useServiceProviders = (page, rowsPerPage, serviceProviderId) => {
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderTotals, setOrderTotals] = useState({});
  const [deliveryTotals, setDeliveryTotals] = useState({});
  const [paymentTotals, setPaymentTotals] = useState({});

  const [loading, setLoading] = useState(false);
  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    supplier_id: serviceProviderId,
  };

  useEffect(() => {
    const fetchData = async () => {
      await makeNetworkCall(
        SUPPLIER_ORDERS_ENDPOINT,
        payload,
        setOrderData,
        setOrderTotals,
        setLoading,
        {}
      );
    };

    fetchData();
  }, [page, rowsPerPage, serviceProviderId]);

  useEffect(() => {
    const fetchData = async () => {
      await makeNetworkCall(
        SUPPLIER_DELIVERIES_ENDPOINT,
        payload,
        setDeliveriesData,
        setDeliveryTotals,
        setLoading,
        {}
      );
    };

    fetchData();
  }, [page, rowsPerPage, serviceProviderId]);

  useEffect(() => {
    const fetchData = async () => {
      await makeNetworkCall(
        SUPPLIER_PAYMENTS_ENDPOINT,
        payload,
        setPaymentsData,
        setPaymentTotals,
        setLoading,
        {}
      );
    };

    fetchData();
  }, [page, rowsPerPage, serviceProviderId]);

  return {
    loading,
    orderTotals,
    deliveryTotals,
    paymentTotals,
    deliveriesData,
    paymentsData,
    orderData,
  };
};

const ServiceProviders = () => {
  const { id, full_name, company_name } = useParams();

  const serviceProviderData = { id, full_name, company_name };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const {
    loading,
    orderTotals,
    deliveryTotals,
    paymentTotals,
    deliveriesData,
    paymentsData,
    orderData,
  } = useServiceProviders(page, rowsPerPage, id);
  const componentRef = useRef();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  return (
    <>
      <Helmet>
        <title>School Service Provider | Procurement</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Stack spacing={3} sx>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack spacing={1}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography sx={{ fontSize: "13px" }} variant="h6">
                  {full_name} - {company_name}
                </Typography>
                <Button
                  onClick={() =>
                    printReport(
                      componentRef,
                      `${
                        value === 0
                          ? "List of service provider orders"
                          : value === 1
                          ? "List of service provider deliveries"
                          : "List of service provider payments"
                      }`
                    )
                  }
                  color="secondary"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <LocalPrintshopIcon />
                    </SvgIcon>
                  }
                >
                  Print
                </Button>
                <ExportTableButton
                  componentRef={componentRef}
                  pageTittle="Service Provider"
                />
              </Stack>
            </Stack>
            <div>
              <AddPurchaseOrders serviceProviderData={serviceProviderData} />
            </div>
          </Stack>

          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Orders" />
              <Tab label="Deliveries" />
              <Tab label="Payments" />
            </Tabs>
          </Box>
          <Box>
            {value === 0 && (
              <PurchaseOrdersTable
                totals={orderTotals}
                items={orderData}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                isLoading={loading}
                componentRef={componentRef}
              />
            )}
            {value === 1 && (
              <DeliveriesTable
                totals={deliveryTotals}
                items={deliveriesData}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                isLoading={loading}
                componentRef={componentRef}
              />
            )}
            {value === 2 && (
              <PaymentTable
                totals={paymentTotals}
                items={paymentsData}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                isLoading={loading}
                componentRef={componentRef}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ServiceProviders;
