// ExpensesTable component
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import { SeverityPill } from "../../components/severity-pill";
import { formatNumber } from "../../utils/number-formter";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {
  DateTimeFormate,
  NormalDateTimeFormate,
} from "../../utils/dateTime-fomater";

export const ExpensesTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Processed By</TableCell>
                <TableCell>Spent By</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date Of Expense</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <ProgressBars />
                  </Box>
                </TableCell>
              </TableRow>
            ) : items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 200,
                    }}
                  >
                    <CustomNoRowsOverlay />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {items.map((expense, index) => {
                  const isSelected = selected.includes(expense.id);

                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow hover key={expense.id} selected={isSelected}>
                      <TableCell>{rowNum}</TableCell>
                      <TableCell>{expense.processed_by.full_name}</TableCell>
                      <TableCell>{expense.spent_by}</TableCell>
                      <TableCell>{expense.type}</TableCell>
                      <TableCell>
                        <SeverityPill color="warning">
                          {`ksh. ${formatNumber(expense.amount)}`}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        {NormalDateTimeFormate(expense.date_of_expense)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

ExpensesTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
