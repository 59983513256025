import PropTypes from "prop-types";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const OverviewRecentDeliveries = (props) => {
    const {orders = [], sx} = props;

    const navigate = useNavigate();

    const handleViewAllClick = () => {
        navigate("/deliveries");
    };

    return (
        <Card sx={sx}>
            <CardHeader title="Recently Delivered Items"/>

            <Box sx={{minWidth: 600}}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> #</TableCell>
                                <TableCell>delivered By</TableCell>
                                <TableCell sortDirection="desc">Item Name</TableCell>
                                <TableCell>Delivered</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order, index) => {
                                const rowNum = 0 * 100 + index + 1;
                                return (
                                    <TableRow hover key={index}>
                                        <TableCell>{rowNum}</TableCell>
                                        <TableCell>{order.delivery.delivered_by}</TableCell>
                                        <TableCell>
                                            {order.delivery.purchase_order?.product?.item_name}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textTransform: "capitalize",
                                            }}
                                        >{`${order.delivery.quantity} out of ${order.total_order_quantity}
                        ${order.delivery.purchase_order.product.unit_of_measurement} `}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Divider/>
            <CardActions sx={{justifyContent: "flex-end"}}>
                <Button
                    onClick={handleViewAllClick}
                    color="inherit"
                    endIcon={
                        <SvgIcon fontSize="small">
                            <ArrowRightIcon/>
                        </SvgIcon>
                    }
                    size="small"
                    variant="text"
                >
                    View all
                </Button>
            </CardActions>
        </Card>
    );
};

OverviewRecentDeliveries.prototype = {
    orders: PropTypes.array,
    sx: PropTypes.object,
};
