import React, {useEffect, useMemo, useState} from "react";
import {useSelection} from "../../hooks/use-selection";
import {DeliveriesTable} from "../../sections/deliveries/deliveries-table";
import {DeliveriesSearch} from "../../sections/deliveries/deliveries-search";
import {SCHOOL_DELIVERIES_ENDPOINT} from "../../utils/constants";
import {makeNetworkCall} from "../../utils/fetchData";
import BasePageConfigs from "../page-configs";
import BasePage from "../page-blueprint";
import {useLoadData} from "../../services/featching-data-service";



const useDeliveryIds = (deliveries) => {
    return useMemo(() => {
        if (!deliveries) {
            return [];
        }
        return deliveries.map((delivery) => delivery.id);
    }, [deliveries]);
};

const DeliveriesPage = () => {
    const {
        page,
        rowsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        componentRef,
    } = BasePageConfigs();

    const payload = {
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
    };


    const {
        data: deliveries,
        totals,
        isLoading,
        setTotals,
        setIsLoading,
        setData,
    } = useLoadData(payload, SCHOOL_DELIVERIES_ENDPOINT);
    const deliveryIds = useDeliveryIds(deliveries);
    const deliveriesSelection = useSelection(deliveryIds);

    return BasePage(
        "Order Deliveries",
        null,
        <DeliveriesSearch
            data={deliveries}
            setLoading={setIsLoading}
            setData={setData}
            setTotals={setTotals}
            payload={payload}
        />,
        <DeliveriesTable
            totals={totals}
            items={deliveries}
            onDeselectAll={deliveriesSelection.handleDeselectAll}
            onDeselectOne={deliveriesSelection.handleDeselectOne}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectAll={deliveriesSelection.handleSelectAll}
            onSelectOne={deliveriesSelection.handleSelectOne}
            page={page}
            rowsPerPage={rowsPerPage}
            selected={deliveriesSelection.selected} // Renamed
            isLoading={isLoading}
            componentRef={componentRef}
        />,
        componentRef
    );
};

export default DeliveriesPage;
