import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {Box, Button, Card, IconButton, InputAdornment, OutlinedInput, SvgIcon, Typography,} from "@mui/material";
import {useState} from "react";
import {SEARCH_EXPENSES_ENDPOINT} from "../../utils/constants";
import {makeNetworkCall} from "../../utils/fetchData";
import ClearIcon from "@mui/icons-material/Clear";
import {useExpenses} from "../../pages/expenses/expenses";

export const ExpensesSearch = ({
                                   setData,
                                   setIsLoading,
                                   page,
                                   rowsPerPage,
                               }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const {data} = useExpenses(page, rowsPerPage);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = async () => {
        const postData = {
            query: searchTerm,
            offset: page * rowsPerPage,
            per_page: rowsPerPage,
        };

        makeNetworkCall(
            SEARCH_EXPENSES_ENDPOINT,
            postData,
            setData,
            null,
            setIsLoading,
            {},
        );

    };
    const handleClearSearch = () => {
        setSearchTerm("");
        setData(data);
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <Card>
            <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
                <OutlinedInput
                    defaultValue=""
                    fullWidth
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Search for an  expense..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SvgIcon color="action" fontSize="small">
                                <MagnifyingGlassIcon/>
                            </SvgIcon>
                        </InputAdornment>
                    }
                    endAdornment={
                        searchTerm && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClearSearch} edge="end">
                                    <ClearIcon/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    sx={{maxWidth: 500, width: "100%"}}
                />
                <Button
                    onClick={handleSearch}
                    fullWidth
                    type="submit"
                    sx={{
                        height: "100%",
                        maxWidth: 100,
                        marginLeft: "20px",
                    }}
                    variant="contained"
                >
                    Search
                </Button>
            </Box>
            <Typography sx={{pl: 2}} variant="body2" color="text.secondary">
                SEARCH HINTS: spent by
            </Typography>
        </Card>
    );
};
