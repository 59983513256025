import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
} from "@mui/material";
import {
  CreateItem,
  fetchDepartmentSettingsData,
  fetchSettingsData,
} from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddStaff({ mode, data }) {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    full_name: data?.full_name || "",
    id: data?.id || "",
    staff_roles: data?.user_role || "", // Adjusted to match data structure
    id_number: data?.id_number || "",
    contact_number: data?.contact_number || "",
    department: data?.department || "",
  });
  const [staffType, setStaffType] = React.useState([]);
  const [staffDepartmentType, setStaffDepartmentType] = React.useState([]);

  React.useEffect(() => {
    fetchSettingsData(setStaffType);
  }, [open]);

  React.useEffect(() => {
    fetchDepartmentSettingsData(setStaffDepartmentType);
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !formValues.full_name ||
      !formValues.staff_roles ||
      !formValues.id_number ||
      !formValues.contact_number ||
      !formValues.department
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Data to be posted
    const postData = {
      id: formValues.id,
      full_name: formValues.full_name,
      user_role: formValues.staff_roles,
      id_number: formValues.id_number,
      contact_number: formValues.contact_number,
      department: formValues.department,
    };

    // Create item
    CreateItem(postData, endpoints.NEW_STAFF_ENDPOINT);
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setFormValues({
        id: data?.id || "",
        full_name: data?.full_name || "",
        staff_roles: data?.user_role || "",
        id_number: data?.id_number || "",
        contact_number: data?.contact_number || "",
        department: data?.department || "",
      });
      handleClickOpen();
    }
  }, [mode, data]);

  return (
    <React.Fragment>
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        onClick={handleClickOpen}
      >
        Add Staff
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader="Fill in all the inputs to be able to create a staff"
                  title="Create Staff"
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Full Name",
                      placeholder: "",
                      field: "full_name",
                    },
                    {
                      labelName: "Contact Number",
                      placeholder: "",
                      field: "contact_number",
                    },
                    {
                      labelName: "ID/TSC No",
                      placeholder: "",
                      field: "id_number",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.labelName}
                          <span style={{ color: "red" }}> *</span>
                        </p>

                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            value={formValues[inputField.field]}
                            fullWidth
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {[
                    {
                      labelName: "Staff Role",
                      placeholder: "",
                      field: "staff_roles",
                    },
                    {
                      labelName: "Staff Department",
                      placeholder: "",
                      field: "department",
                    },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "start",
                        }}
                      >
                        <p>
                          {inputField.labelName}
                          <span style={{ color: "red" }}> *</span>
                        </p>
                        <FormControl fullWidth>
                          <InputLabel>{inputField.labelName}</InputLabel>
                          <Select
                            value={formValues[inputField.field]}
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          >
                            {inputField.field === "department"
                              ? staffDepartmentType?.departments
                                  ?.split(",")
                                  ?.map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))
                              : staffType?.staff_roles
                                  ?.split(",")
                                  ?.map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                          </Select>
                        </FormControl>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
