import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  SvgIcon,
  OutlinedInput,
  Grid,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CreateItem, fetchSettingsData } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFormState = {
  unit_price: "0.00",
  item_name: "",
  initial_stock: "0",
  unit_of_measurement: "PSC",
  notes: "",
  is_consumable: false,
  item_number: "1",
  category: "",
};

export default function AddLso({ mode, data }) {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState(initialFormState);
  const [settingsType, setSettingsType] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      // Initialize formValues with data
      setFormValues({
        unit_price: data.unit_price,
        item_name: data.product.item_name,
        initial_stock: data.product.initial_stock.toString(),
        unit_of_measurement: data.product.unit_of_measurement,
        notes: data.product.notes,
        is_consumable: data.product.is_consumable,
        item_number: data.product.item_number,
        category: data.product.category,
      });
      handleClickOpen();
    }
  }, [data]);

  React.useEffect(() => {
    fetchSettingsData(setSettingsType);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    // For is_consumable field, convert the value to a boolean
    const sanitizedValue =
      field === "is_consumable" ? value === "true" || value === true : value;

    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: sanitizedValue,
    }));
  };

  const handleSubmit = async () => {
    if (
      !formValues.item_name ||
      !formValues.category
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Data to be posted
    const postData = {
      id: data?.id,
      unit_price: formValues.unit_price,
      item_name: formValues.item_name,
      initial_stock: 0,
      unit_of_measurement: formValues.unit_of_measurement,
      notes: formValues.notes,
      is_consumable: formValues.is_consumable,
      item_number: formValues.item_number,
      is_tangible: "false",
      category: formValues.category,
    };

    await CreateItem(postData, endpoints.NEW_INVENTORY_ENDPOINT);
  };

  return (
    <React.Fragment>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PlusIcon />
          </SvgIcon>
        }
        variant="contained"
        onClick={handleClickOpen}
      >
        Create an LSO Service
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {mode === "edit" ? "Edit" : "Create"} an LSO Service
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    mode === "edit" ? "EDIT" : "CREATE"
                  } AN LSO SERVICE`}
                  title={`${
                    mode === "edit" ? "Edit" : "Create"
                  } an LSO Service`}
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Item Name
                      <span style={{ color: "red" }}> *</span>
                    </p>

                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        value={formValues.item_name}
                        fullWidth
                        onChange={(e) =>
                          handleInputChange("item_name", e.target.value)
                        }
                      />
                    </FormControl>
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Category
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={
                          settingsType?.item_categories?.split(",") || []
                        }
                        value={formValues.category}
                        onChange={(event, value) =>
                          handleInputChange("category", value)
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
