import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SvgIcon,
} from "@mui/material";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import { CreateItem } from "../../utils/fetchData";
import { formatNumber } from "../../utils/number-formter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPayment({ purchaseOrderData, data, mode }) {
  const order = purchaseOrderData?.order || data?.payment?.purchase_order;
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    cheque_number: data?.payment.cheque_number || "",
    amount: data?.payment.amount || "",
    mode_of_payment: data?.payment.mode_of_payment || "",
  });
  const [modeOfPayment, setModeOfPayment] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    // Validation checks
    if (!order.id || !formValues.amount || !formValues.mode_of_payment) {
      toast.error("Please fill in all required fields.");
      return;
    }
    // Data to be posted
    const postData = {
      cheque_number: formValues.cheque_number,
      purchase_order_id: order.id,
      amount: formValues.amount,
      mode_of_payment: formValues.mode_of_payment,
      id: data?.payment?.id,
    };

    // Create item
    CreateItem(postData, endpoints.NEW_PAYMENT_ENDPOINT);
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setFormValues({
        cheque_number: data?.payment.cheque_number || "",
        amount: data?.payment.amount || "",
        mode_of_payment: data?.payment.mode_of_payment || "",
      });
      handleClickOpen();
    }
  }, [mode, data]);
  return (
    <React.Fragment>
      {mode === "edit" ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={handleClickOpen}
        >
          Make Payment
        </Button>
      )}

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  title={
                    <>
                      <Box>
                        <Typography
                          variant="h6"
                          component="span"
                          sx={{ fontWeight: "normal" }}
                        >
                          PAYEE:
                        </Typography>
                        <Typography
                          variant="h6"
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {` ${order?.supplier?.company_name}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{ fontWeight: "normal" }}
                        >
                          PURPOSE:
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {` LPO No. ${order?.order_number}`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: "normal", fontSize: "0.75rem" }}
                        >
                          NOTE:
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: "normal", fontSize: "0.75rem" }}
                        >
                          {` Ensure that all fields are filled accurately before submitting`}
                        </Typography>
                      </Box>
                    </>
                  }
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/* <ListItem>
                    <FormControl fullWidth>
                      <p>
                        Purchase Order
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      <Autocomplete
                        options={purchaseOrderIdData}
                        getOptionLabel={(option) => {
                          if (option && option.supplier && option.product) {
                            const companyName =
                              option.supplier.company_name.toUpperCase();
                            const productName =
                              option.product.item_name.toUpperCase();
                            return `#${option.order_number}: ${productName} - ${companyName}`;
                          }
                          return "";
                        }}
                        value={
                          selectedPurchaseOrderId
                            ? purchaseOrderIdData.find(
                                (data) => data.id === selectedPurchaseOrderId
                              )
                            : null
                        }
                        onChange={(event, newValue) =>
                          handlePurchaseSelection(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Purchase Order"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </ListItem> */}
                  {[
                    { labelName: "Amount", placeholder: "", field: "amount" },
                  ].map((inputField, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <p>
                          {inputField.labelName}
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *{" "}
                          </span>
                        </p>
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            defaultValue=""
                            value={formatNumber(formValues[inputField.field])}
                            fullWidth
                            onChange={(e) =>
                              handleInputChange(
                                inputField.field,
                                e.target.value.replace(/,/g, "")
                              )
                            }
                          />
                        </FormControl>
                      </ListItem>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="mode-of-payment-label">
                        Mode of Payment
                      </InputLabel>
                      <Select
                        labelId="mode-of-payment-label"
                        value={formValues.mode_of_payment}
                        onChange={(e) => {
                          setModeOfPayment(e.target.value);
                          handleInputChange("mode_of_payment", e.target.value);
                        }}
                        label="Mode of Payment"
                      >
                        <MenuItem value={"CHEQUE"}>CHEQUE</MenuItem>
                        <MenuItem value={"IN-KIND"}>IN-KIND</MenuItem>
                        <MenuItem value={"CASH"}>CASH</MenuItem>
                        <MenuItem value={"OTHER"}>OTHER</MenuItem>
                      </Select>
                    </FormControl>
                    {[
                      {
                        labelName: "Cheque Number",
                        placeholder: "",
                        field: "cheque_number",
                      },
                    ].map((inputField, index) => (
                      <React.Fragment key={index}>
                        {modeOfPayment === "CHEQUE" && (
                          <ListItem
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "start",
                            }}
                          >
                            <p>
                              {inputField.labelName}
                              <span style={{ color: "red", marginLeft: "5px" }}>
                                *{" "}
                              </span>
                            </p>
                            <FormControl sx={{ width: "100%" }}>
                              <OutlinedInput
                                defaultValue=""
                                value={formValues[inputField.field]}
                                fullWidth
                                onChange={(e) =>
                                  handleInputChange(
                                    inputField.field,
                                    e.target.value
                                  )
                                }
                                disabled={modeOfPayment !== "CHEQUE"}
                              />
                            </FormControl>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </ListItem>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
