import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";

export default function BasicDatePicker({ date, setDate, label }) {

  const formatDate = (date) => {
    const year = date.$y;
    const month = date.$M + 1 < 10 ? `0${date.$M + 1}` : date.$M + 1;
    const day = date.$D < 10 ? `0${date.$D}` : date.$D;
    return `${year}-${month}-${day}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem label={label}>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(formatDate(date))}
          views={["year", "month", "day"]}
        />
      </DemoItem>
    </LocalizationProvider>
  );
}
