import React from "react";
import PropTypes from "prop-types";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const OverviewSchoolProducts = (props) => {
  const { products = [], sx } = props;

  const navigate = useNavigate();

  const handleViewAllClick = () => {
    navigate("/inventory");
  };

  const formatNumber = (number) => {
    if (number < 10000) {
      return new Intl.NumberFormat().format(number);
    } else if (number < 1000000) {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
      }).format(number / 1000);
      return `${formattedNumber}k`;
    } else if (number < 1000000000) {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
      }).format(number / 1000000);
      return `${formattedNumber}M`;
    } else {
      const formattedNumber = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
      }).format(number / 1000000000);
      return `${formattedNumber}B`;
    }
  };

  return (
    <Card sx={sx}>
      <CardHeader title="School Inventory" />
      <Box sx={{ minWidth: 600 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Delivered</TableCell>
                <TableCell>In Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => {
                const rowNum = 0 * 100 + index + 1;

                return (
                  <TableRow key={index}>
                    <TableCell>{rowNum}</TableCell>
                    <TableCell>{product.product}</TableCell>
                    <TableCell>{` ${formatNumber(
                      product.delivered_quantity
                    )} out of ${formatNumber(
                      product.ordered_quantity
                    )}`}</TableCell>
                    <TableCell>
                      {product.delivered_quantity - product.quantity_allocated}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={handleViewAllClick}
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};

OverviewSchoolProducts.propTypes = {
  products: PropTypes.array,
  sx: PropTypes.object,
};
